import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from "../logos/FlowConnect.svg";
import { UserContext } from "../context/UserProvider";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, logIn, logOut } = useContext(UserContext);

  return (
    <>
      <nav className="bg-gray-400 p-4 flex justify-between items-center">
        <NavLink
          to="/"
          className="flex flex-col items-center ml-4 hover:scale-105 transition-transform duration-300"
        >
          <img src={Logo} alt="Flow Connect logo" className="h-16 sm:h-16" />
        </NavLink>

        {/* Hamburger for Mobile */}
        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-gray-700 focus:outline-none"
          >
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Desktop Nav */}
        <div className="hidden lg:flex space-x-8 items-center">
          <NavLink
            to="/news"
            className="px-4 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center"
          >
            News
          </NavLink>
          <NavLink
            to="/events"
            className="px-4 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center"
          >
            Events
          </NavLink>
          <NavLink
            to="/topshot"
            className="px-4 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 flex items-center justify-center"
          >
            TopShot Browser
          </NavLink>

          {/* Connect / Logout */}
          {user?.loggedIn ? (
            <div className="flex items-center space-x-2">
              <span className="text-black font-medium">
                Connected: {user.addr}
              </span>
              <button
                onClick={logOut}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                Log Out
              </button>
            </div>
          ) : (
            <button
              onClick={logIn}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Connect
            </button>
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="lg:hidden flex flex-col items-center bg-gray-400 shadow-lg p-4 space-y-4 w-full px-6">
          <NavLink
            to="/news"
            className="px-6 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 w-full text-center"
            onClick={() => setMenuOpen(false)}
          >
            News
          </NavLink>
          <NavLink
            to="/events"
            className="px-6 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 w-full text-center"
            onClick={() => setMenuOpen(false)}
          >
            Events
          </NavLink>
          <NavLink
            to="/topshot"
            className="px-6 py-2 bg-white text-black rounded-md hover:scale-105 transition-transform duration-300 w-full text-center"
            onClick={() => setMenuOpen(false)}
          >
            TopShot Browser
          </NavLink>

          {user?.loggedIn ? (
            <div className="flex flex-col items-center w-full">
              <span className="text-black mb-2 font-medium">
                Connected: {user.addr}
              </span>
              <button
                onClick={() => {
                  logOut();
                  setMenuOpen(false);
                }}
                className="w-full px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                Log Out
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                logIn();
                setMenuOpen(false);
              }}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Connect
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
