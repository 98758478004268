// pages/TopShotSeries.js
import React, { useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { TopShotDataContext } from "./TopShot";
import { FaLock } from "react-icons/fa";
import {
  getSetBackgroundStyle,
  TIER_CONFIG,
  TIER_ORDER,
  TIER_LABEL_MAP,
  getTierButtonClasses,
} from "../components/TopShotHelpers";

// Reorders a multi-tier string by TIER_CONFIG priority
function reorderTierString(rawTier = "") {
  const tierStr = rawTier ? String(rawTier) : "";
  if (!tierStr) return "";
  const parts = tierStr
    .split("/")
    .map((p) => p.trim().toLowerCase())
    .filter(Boolean);
  parts.sort(
    (a, b) =>
      (TIER_CONFIG[a]?.priority ?? 99) - (TIER_CONFIG[b]?.priority ?? 99)
  );
  return parts.join("/");
}

// Formats "rare/common" => "Rare/Common"
function formatTierForDisplay(tierString = "") {
  return tierString
    .split("/")
    .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
    .join("/");
}

// For "rare/common" => "mixed", empty => "unknown"
function getTierLabelForFilter(sortedTier) {
  if (!sortedTier) return "unknown";
  return sortedTier.includes("/") ? "mixed" : sortedTier;
}

// Optional local helper for status button color only
function getStatusButtonStyle(status) {
  if (status === "open") return { backgroundColor: "green", color: "white" };
  if (status === "locked") return { backgroundColor: "red", color: "white" };
  return { backgroundColor: "#6B7280", color: "white" };
}

export default function TopShotSeries() {
  const { seriesId } = useParams();
  const {
    series = [],
    sets = [],
    tiers = {},
  } = useContext(TopShotDataContext) || {};

  // Filter states
  const [selectedSeries, setSelectedSeries] = useState("all");
  const [selectedTier, setSelectedTier] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");

  // Collapsible filters (mobile)
  const [filtersOpen, setFiltersOpen] = useState(false);
  function toggleFilters() {
    setFiltersOpen(!filtersOpen);
  }

  // Sort series by id
  const sortedSeries = [...series].sort((a, b) => a.id - b.id);

  // ---------- Filter Logic ----------
  let filteredSets = [...sets];

  // 1) Series
  if (selectedSeries !== "all") {
    filteredSets = filteredSets.filter(
      (s) => s.series === Number(selectedSeries)
    );
  }
  // Exclude certain IDs
  const EXCLUDED_IDS = [155, 156, 158];
  filteredSets = filteredSets.filter((s) => !EXCLUDED_IDS.includes(s.id));

  // 2) Status
  if (selectedStatus !== "all") {
    const wantLocked = selectedStatus === "locked";
    filteredSets = filteredSets.filter((s) => s.locked === wantLocked);
  }

  // 3) Tier
  if (selectedTier !== "all") {
    filteredSets = filteredSets.filter((s) => {
      const rawTier = tiers[s.id] || "";
      const sortedTier = reorderTierString(rawTier);
      const label = getTierLabelForFilter(sortedTier);
      return label === selectedTier;
    });
  }

  return (
    <div className="p-2">
      {/* Mobile "Filters" button */}
      <div className="flex items-center justify-end mb-2">
        <button
          className="px-3 py-1 bg-gray-700 text-white rounded md:hidden"
          onClick={toggleFilters}
          aria-expanded={filtersOpen}
          aria-controls="filtersPanel"
        >
          Filters
        </button>
      </div>

      {/* Filter Panel */}
      <div
        id="filtersPanel"
        className={`${
          filtersOpen ? "block" : "hidden"
        } md:block border rounded mb-4 p-2`}
      >
        {/* SERIES Filter */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Series</h3>
          <div className="flex flex-wrap gap-2">
            {/* "All" */}
            <button
              onClick={() => setSelectedSeries("all")}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedSeries === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "#6B7280" }}
            >
              All
            </button>
            {sortedSeries.map((serie) => (
              <button
                key={serie.id}
                onClick={() => setSelectedSeries(String(serie.id))}
                className={`px-3 py-1 rounded text-white text-sm ${
                  selectedSeries === String(serie.id)
                    ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                    : ""
                }`}
                style={{ backgroundColor: "#6B7280" }}
              >
                {serie.id}
              </button>
            ))}
          </div>
        </div>

        {/* TIER Filter */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Tier</h3>
          <div className="flex flex-wrap gap-2">
            {/* "All" tier */}
            <button
              onClick={() => setSelectedTier("all")}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedTier === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{
                backgroundImage: "linear-gradient(to right, #6B7280, #6B7280)",
              }}
            >
              All
            </button>

            {/* TIER_ORDER tiers */}
            {TIER_ORDER.map((tierKey) => {
              const selected =
                selectedTier === tierKey
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : "";
              return (
                <button
                  key={tierKey}
                  onClick={() => setSelectedTier(tierKey)}
                  className={`px-3 py-1 rounded text-sm ${selected} ${getTierButtonClasses(
                    tierKey
                  )}`}
                >
                  {TIER_LABEL_MAP[tierKey]}
                </button>
              );
            })}
          </div>
        </div>

        {/* STATUS Filter */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Status</h3>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedStatus("all")}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedStatus === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "#6B7280" }}
            >
              All
            </button>
            <button
              onClick={() => setSelectedStatus("open")}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedStatus === "open"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "green" }}
            >
              Open
            </button>
            <button
              onClick={() => setSelectedStatus("locked")}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedStatus === "locked"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "red" }}
            >
              Locked
            </button>
          </div>
        </div>
      </div>

      {/* RESULTS COUNT */}
      <div className="mb-4">
        <p className="text-lg font-bold">
          {filteredSets.length} sets matched your filters.
        </p>
      </div>

      {/* GRID of sets (2->5 columns) */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1">
        {filteredSets.map((set) => {
          const rawTier = tiers[set.id] || "";
          const sortedTier = reorderTierString(rawTier);
          const displayTier = formatTierForDisplay(sortedTier);
          const bgClasses = getSetBackgroundStyle(sortedTier);

          return (
            <Link
              key={set.id}
              to={`/topshot/sets/${set.id}`}
              className="no-underline"
            >
              <div
                className={`relative hover:scale-105 transition-transform duration-200 ${bgClasses} text-white bg-[length:200%_200%] p-1 rounded shadow-md flex flex-col justify-between`}
                style={{ height: "260px" }}
              >
                {/* Image */}
                <img
                  src={`https://storage.googleapis.com/flowconnect/topshot/sets_small/${set.id}.jpg`}
                  alt={set.name}
                  style={{
                    width: "100%",
                    maxHeight: "13rem",
                    objectFit: "cover",
                    borderRadius: "0.5rem",
                  }}
                  onError={(e) => {
                    e.target.src =
                      "https://storage.googleapis.com/flowconnect/topshot/sets_small/placeholder.jpg";
                  }}
                />
                {/* Lock/Open badge */}
                <div
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                  }}
                >
                  <div className="px-2 py-1 text-xs rounded-lg font-bold flex items-center bg-green-600">
                    {set.locked && <FaLock className="mr-1" />}
                    {set.locked ? "Locked" : "Open"}
                  </div>
                </div>

                {/* Set name / tier */}
                <p className="text-center font-bold mt-1 text-sm">{set.name}</p>
                <p className="text-center text-xs font-medium mt-1">
                  {displayTier}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
