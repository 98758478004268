import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import CalendarComponent from "../components/CalendarComponent";
import CalendarToday from "../components/CalendarToday";
import CalendarUpcoming from "../components/CalendarUpcoming";

import TopShotLogo from "../logos/TopShot-white.svg";
import { shortenText, getSourceLabel } from "../components/RSSUtilities";

// Simple skeleton loader component
function NewsSkeleton({ count }) {
  const placeholders = Array.from({ length: count });
  return (
    <>
      {placeholders.map((_, idx) => (
        <li
          key={idx}
          className="animate-pulse flex flex-col md:flex-row mb-4 p-2 border-b items-stretch"
        >
          {/* Left-side image placeholder */}
          <div className="w-full md:w-1/4 mb-4 md:mb-0 md:mr-4 flex-shrink-0 bg-gray-300 h-32 rounded-md" />
          {/* Right-side text placeholders */}
          <div className="flex flex-col justify-start w-full md:w-3/4">
            <div className="bg-gray-300 h-4 w-1/2 mb-2 rounded" />
            <div className="bg-gray-300 h-6 w-full mb-2 rounded" />
            <div className="bg-gray-300 h-4 w-3/4 mb-2 rounded" />
          </div>
        </li>
      ))}
    </>
  );
}

function HomePage() {
  const navigate = useNavigate();

  // News data & pagination
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const limit = 4; // Number of articles per page on the HomePage

  // Fetch a specific page of news from your endpoint
  const fetchNewsPage = async (pageNum) => {
    setIsLoading(true);
    try {
      // e.g. GET /news?page=1&limit=4
      const response = await axios.get(
        `https://api.vaultopolis.com/news?page=${pageNum}&limit=${limit}`
      );
      const data = response.data; // { totalItems, totalPages, currentPage, items }
      setNewsItems(data.items || []);
      setCurrentPage(data.currentPage || pageNum);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching paginated news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // On mount, load page=1
  useEffect(() => {
    fetchNewsPage(1);
    // eslint-disable-next-line
  }, []);

  // Handler for "Explore Projects" button(s)
  const handleExploreTopShot = () => {
    navigate("/topshot");
  };

  // Pagination handlers
  const handlePrev = () => {
    if (currentPage > 1) {
      fetchNewsPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      fetchNewsPage(currentPage + 1);
    }
  };

  // "Explore Projects" sub-component
  const ExploreProjects = () => (
    <div className="mt-4">
      <h3 className="text-xl font-bold text-gray-800 mb-2">Explore Projects</h3>
      <div className="space-y-4">
        <div className="flex items-center justify-center bg-gradient-to-r from-blue-400 via-gray-400 to-red-400 p-4 rounded-lg shadow-lg h-24">
          <button
            onClick={handleExploreTopShot}
            className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img src={TopShotLogo} alt="NBA TopShot Logo" className="w-48" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-2 bg-white">
      <div className="flex flex-wrap">
        {/* Main News Section */}
        <div className="w-full md:w-[65%]">
          <h2 className="text-2xl font-bold">Latest News</h2>

          {isLoading && newsItems.length === 0 ? (
            <p>Loading news...</p>
          ) : newsItems.length === 0 ? (
            <p>No news items found.</p>
          ) : (
            <ul className="space-y-4 mt-2">
              {newsItems.map((item) => (
                <li
                  key={item.uniqueId}
                  className="flex flex-col md:flex-row mb-4 p-2 border-b items-stretch"
                >
                  {/* Image section with a smaller container */}
                  <div className="w-full md:w-1/4 mb-4 md:mb-0 md:mr-4 flex-shrink-0">
                    <div
                      className="relative w-full"
                      style={{ paddingBottom: "50%" }}
                    >
                      {item.image ? (
                        <img
                          src={item.image}
                          alt={item.title}
                          className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
                          style={{ objectPosition: "50% 60%" }}
                          onError={(e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src =
                              "https://via.placeholder.com/150?text=No+Image";
                          }}
                        />
                      ) : (
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-gray-400">
                          No Image
                        </div>
                      )}
                    </div>
                  </div>

                  {/* News content section */}
                  <div className="flex flex-col justify-start w-full md:w-3/4">
                    <p className="text-sm text-gray-500">
                      {getSourceLabel(item.source, item.url)}
                    </p>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block text-lg font-semibold text-blue-600 hover:underline mt-1"
                    >
                      {item.title}
                    </a>
                    {item.description &&
                      item.description !== "No description available." && (
                        <p className="mt-2">
                          {shortenText(item.description, 120)}
                        </p>
                      )}
                    <p className="text-gray-500 text-sm mt-2">
                      Published on:{" "}
                      {item.publishedDate
                        ? new Date(item.publishedDate).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </div>
                </li>
              ))}

              {isLoading && newsItems.length > 0 && (
                <NewsSkeleton count={limit} />
              )}
            </ul>
          )}

          {/* Pagination Controls */}
          <div className="flex justify-center space-x-4 mt-4">
            <button
              onClick={handlePrev}
              disabled={currentPage <= 1}
              className={`px-4 py-2 rounded-md ${
                currentPage <= 1
                  ? "bg-gray-300 text-gray-600"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={currentPage >= totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage >= totalPages
                  ? "bg-gray-300 text-gray-600"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-[35%] p-2">
          <CalendarToday />
          <CalendarUpcoming />
          <ExploreProjects />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
