import React, {
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";
import * as fcl from "@onflow/fcl";
import pLimit from "p-limit";

// Flow scripts
import { verifyTopShotCollection } from "../flow/verifyTopShotCollection";
import { getTopShotCollectionIDs } from "../flow/getTopShotCollectionIDs";
import { getTopShotCollectionBatched } from "../flow/getTopShotCollectionBatched";
import { getFLOWBalance } from "../flow/getFLOWBalance";
import { getTSHOTBalance } from "../flow/getTSHOTBalance";
import { hasChildren as hasChildrenCadence } from "../flow/hasChildren";
import { getChildren } from "../flow/getChildren";

// Hardcoded subedition data, if relevant
const SUBEDITIONS = {
  1: { name: "Explosion", minted: 500 },
  2: { name: "Torn", minted: 1000 },
  3: { name: "Vortex", minted: 2500 },
  4: { name: "Rippled", minted: 4000 },
  5: { name: "Coded", minted: 25 },
  6: { name: "Halftone", minted: 100 },
  7: { name: "Bubbled", minted: 250 },
  8: { name: "Diced", minted: 10 },
  9: { name: "Bit", minted: 50 },
  10: { name: "Vibe", minted: 5 },
  11: { name: "Astra", minted: 75 },
};

/************************************************************
 * 1. Create UserContext
 ************************************************************/
export const UserContext = createContext();

/************************************************************
 * 2. Helper: Enrich NFT data with subedition + metadata
 ************************************************************/
const enrichWithMetadata = async (details, metadataCache) => {
  return details.map((nft) => {
    const key = `${nft.setID}-${nft.playID}`;
    const meta = metadataCache ? metadataCache[key] : undefined;

    let enriched = { ...nft };

    // Merge known data from your backend cache (if any)
    if (meta) {
      enriched.tier = meta.tier || enriched.tier;
      enriched.fullName =
        meta.FullName ||
        meta.fullName ||
        enriched.fullName ||
        enriched.playerName ||
        "Unknown Player";
      enriched.momentCount = Number(meta.momentCount) || enriched.momentCount;
      enriched.jerseyNumber =
        meta.JerseyNumber || meta.jerseyNumber || enriched.jerseyNumber;
      enriched.retired =
        meta.retired !== undefined ? meta.retired : enriched.retired;
      enriched.playOrder = meta.playOrder || enriched.playOrder;
      if (meta.series !== undefined && meta.series !== null) {
        enriched.series = meta.series;
      }
      enriched.name = meta.name || enriched.name;

      if (meta.TeamAtMoment) {
        enriched.teamAtMoment = meta.TeamAtMoment;
      }
    } else {
      console.warn(
        `No metadata found for setID=${nft.setID}, playID=${nft.playID}.
         This NFT may appear missing data if it’s a subedition or newly minted.`
      );
    }

    // Merge subedition data if subeditionID is present
    if (nft.subeditionID && SUBEDITIONS[nft.subeditionID]) {
      const sub = SUBEDITIONS[nft.subeditionID];
      enriched.subeditionName = sub.name;
      enriched.subeditionMaxMint = sub.minted;
    }

    return enriched;
  });
};

/************************************************************
 * 3. Initial State
 ************************************************************/
const initialState = {
  user: { loggedIn: null, addr: "" },
  accountData: {
    parentAddress: null,
    nftDetails: [],
    flowBalance: null,
    tshotBalance: null,
    hasCollection: null,
    hasChildren: false,
    childrenData: [],
    childrenAddresses: [],
  },
  selectedAccount: null,
  selectedAccountType: "parent",
  selectedNFTs: [],
  isRefreshing: false,
  isLoadingChildren: false,
  metadataCache: null,
};

/************************************************************
 * 4. Reducer
 ************************************************************/
function userReducer(state, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };

    case "SET_METADATA_CACHE":
      return { ...state, metadataCache: action.payload };

    case "SET_ACCOUNT_DATA":
      return {
        ...state,
        accountData: { ...state.accountData, ...action.payload },
      };

    case "SET_SELECTED_NFTS": {
      const isSelected = state.selectedNFTs.includes(action.payload);
      return {
        ...state,
        selectedNFTs: isSelected
          ? state.selectedNFTs.filter((id) => id !== action.payload)
          : [...state.selectedNFTs, action.payload],
      };
    }

    case "RESET_SELECTED_NFTS":
      return { ...state, selectedNFTs: [] };

    case "RESET_STATE":
      return { ...initialState, user: { loggedIn: false } };

    case "SET_CHILDREN_DATA":
      return {
        ...state,
        accountData: {
          ...state.accountData,
          childrenData: action.payload,
        },
      };

    case "SET_CHILDREN_ADDRESSES":
      return {
        ...state,
        accountData: {
          ...state.accountData,
          childrenAddresses: action.payload,
        },
      };

    case "SET_REFRESHING_STATE":
      return { ...state, isRefreshing: action.payload };

    case "SET_LOADING_CHILDREN":
      return { ...state, isLoadingChildren: action.payload };

    default:
      return state;
  }
}

/************************************************************
 * 5. Main Provider
 ************************************************************/
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const [didLoad, setDidLoad] = useState(false);

  /**
   * 5a) logIn / logOut
   */
  const logIn = async () => {
    try {
      await fcl.authenticate();
    } catch (err) {
      console.error("Error logging in:", err);
    }
  };

  const logOut = () => {
    fcl.unauthenticate();
    dispatch({ type: "RESET_STATE" });
  };

  /**
   * 5b) Basic fetch functions (Flow & TSHOT balances)
   */
  const fetchFLOWBalance = useCallback(async (address) => {
    if (!address?.startsWith("0x")) return 0;
    try {
      return await fcl.query({
        cadence: getFLOWBalance,
        args: (arg, t) => [arg(address, t.Address)],
      });
    } catch {
      return 0;
    }
  }, []);

  const fetchTSHOTBalance = useCallback(async (address) => {
    if (!address?.startsWith("0x")) return 0;
    try {
      return await fcl.query({
        cadence: getTSHOTBalance,
        args: (arg, t) => [arg(address, t.Address)],
      });
    } catch {
      return 0;
    }
  }, []);

  /**
   * 5c) loadTopShotMetadata => localStorage caching or fetch from backend
   */
  const loadTopShotMetadata = useCallback(async () => {
    try {
      // For example, we fetch new data if older than 1 hour
      const ONE_HOUR = 3600000;
      const now = Date.now();

      const raw = localStorage.getItem("topshotMetadata");
      if (raw) {
        const parsed = JSON.parse(raw);
        if (parsed.timestamp && parsed.data) {
          const age = now - parsed.timestamp;
          if (age < ONE_HOUR) {
            // Use cached data
            dispatch({ type: "SET_METADATA_CACHE", payload: parsed.data });
            return parsed.data;
          }
        } else {
          // If old style, just use it as the map
          dispatch({ type: "SET_METADATA_CACHE", payload: parsed });
          return parsed;
        }
      }

      // Otherwise fetch from your server (if you have one),
      // or comment out if you DO NOT have a real server
      const fresh = await fetchRemoteTopShotMetadata();
      return fresh;
    } catch (err) {
      console.error("Error loading TopShot metadata:", err);
      return {};
    }
  }, [dispatch]);

  // Force metadata refresh from your server
  const fetchRemoteTopShotMetadata = async () => {
    console.log(
      "[fetchRemoteTopShotMetadata] Starting forced metadata fetch..."
    );
    try {
      // CHANGEME: If you have a real backend with CORS enabled, use that
      // Otherwise, comment out the call or set an actual endpoint
      const now = Date.now();
      const baseUrl =
        process.env.REACT_APP_API_BASE_URL || "https://api.vaultopolis.com";
      // or any valid URL with CORS enabled

      const resp = await fetch(`${baseUrl}/topshot-data`);
      if (!resp.ok) {
        throw new Error(`HTTP Error: ${resp.status}`);
      }

      const data = await resp.json();
      // Build a map: { "setID-playID": {...}, ... }
      const metadataMap = data.reduce((acc, item) => {
        const key = `${item.setID}-${item.playID}`;
        acc[key] = item;
        return acc;
      }, {});

      // Save to localStorage
      const toStore = {
        timestamp: now,
        data: metadataMap,
      };
      localStorage.setItem("topshotMetadata", JSON.stringify(toStore));
      dispatch({ type: "SET_METADATA_CACHE", payload: metadataMap });

      return metadataMap;
    } catch (err) {
      console.error("Error in forceFetchTopShotMetadata:", err);
      return {};
    }
  };

  /**
   * 5d) fetchTopShotCollection => get moment IDs, retrieve details in batches
   */
  const fetchTopShotCollection = useCallback(
    async (address) => {
      if (!address?.startsWith("0x")) {
        return { hasCollection: false, details: [], tierCounts: {} };
      }

      try {
        // 1) Check if user has a collection
        const hasColl = await fcl.query({
          cadence: verifyTopShotCollection,
          args: (arg, t) => [arg(address, t.Address)],
        });
        if (!hasColl) {
          return { hasCollection: false, details: [], tierCounts: {} };
        }

        // 2) Grab all moment IDs
        const ids = await fcl.query({
          cadence: getTopShotCollectionIDs,
          args: (arg, t) => [arg(address, t.Address)],
        });
        if (!ids || ids.length === 0) {
          return { hasCollection: true, details: [], tierCounts: {} };
        }

        // 3) Batch them
        const limit = pLimit(30);
        const batchSize = 2500;
        const batches = [];
        for (let i = 0; i < ids.length; i += batchSize) {
          batches.push(ids.slice(i, i + batchSize));
        }

        const allResults = await Promise.all(
          batches.map((batch) =>
            limit(() =>
              fcl.query({
                cadence: getTopShotCollectionBatched,
                args: (arg, t) => [
                  arg(address, t.Address),
                  arg(batch, t.Array(t.UInt64)),
                ],
              })
            )
          )
        );

        // Flatten
        const rawNFTs = allResults.flat().map((n) => ({
          ...n,
          id: Number(n.id),
          setID: Number(n.setID),
          playID: Number(n.playID),
          serialNumber: Number(n.serialNumber),
          isLocked: Boolean(n.isLocked),
          subeditionID: n.subeditionID != null ? Number(n.subeditionID) : null,
        }));

        // 4) Check if any new NFT IDs are missing from metadata cache
        let currentCache = state.metadataCache;
        if (!currentCache) {
          currentCache = await loadTopShotMetadata();
        }
        const missingMetadata = rawNFTs.some((nft) => {
          const key = `${nft.setID}-${nft.playID}`;
          return !currentCache[key];
        });

        if (missingMetadata) {
          console.log(
            "[fetchTopShotCollection] Found new NFT IDs -> Forcing metadata refresh..."
          );
          console.time("[Metadata Refresh Time]");
          currentCache = await fetchRemoteTopShotMetadata();
          console.timeEnd("[Metadata Refresh Time]");
        }

        // 5) Enrich with metadata
        const enriched = await enrichWithMetadata(rawNFTs, currentCache);

        // Example tierCounts
        const tierCounts = enriched.reduce((acc, nft) => {
          const tier = nft.tier?.toLowerCase();
          if (tier) {
            acc[tier] = (acc[tier] || 0) + 1;
          }
          return acc;
        }, {});

        return { hasCollection: true, details: enriched, tierCounts };
      } catch (err) {
        console.error("Error fetching collection for", address, err);
        return { hasCollection: false, details: [], tierCounts: {} };
      }
    },
    [state.metadataCache, loadTopShotMetadata]
  );

  /**
   * 5e) loadParentData => parent's NFT collection, balances, etc.
   */
  const loadParentData = useCallback(
    async (addr) => {
      if (!addr || !addr.startsWith("0x")) return;
      dispatch({ type: "SET_REFRESHING_STATE", payload: true });
      try {
        const [flowBal, tshotBal, colData] = await Promise.all([
          fetchFLOWBalance(addr),
          fetchTSHOTBalance(addr),
          fetchTopShotCollection(addr),
        ]);
        dispatch({
          type: "SET_ACCOUNT_DATA",
          payload: {
            parentAddress: addr,
            flowBalance: flowBal,
            tshotBalance: tshotBal,
            nftDetails: colData.details || [],
            hasCollection: colData.hasCollection,
            tierCounts: colData.tierCounts || {},
          },
        });
      } catch (err) {
        console.error("Error loading parent data:", err);
      } finally {
        dispatch({ type: "SET_REFRESHING_STATE", payload: false });
      }
    },
    [fetchFLOWBalance, fetchTSHOTBalance, fetchTopShotCollection]
  );

  /**
   * 5f) loadChildData => single child's NFT collection
   */
  const loadChildData = useCallback(
    async (childAddr) => {
      if (!childAddr || !childAddr.startsWith("0x")) return;
      try {
        const [flowBal, tshotBal, colData] = await Promise.all([
          fetchFLOWBalance(childAddr),
          fetchTSHOTBalance(childAddr),
          fetchTopShotCollection(childAddr),
        ]);

        // Store child data
        dispatch((prev) => {
          const oldList = prev.accountData.childrenData || [];
          const updatedChild = {
            addr: childAddr,
            flowBalance: flowBal,
            tshotBalance: tshotBal,
            nftDetails: colData.details || [],
            hasCollection: colData.hasCollection,
            tierCounts: colData.tierCounts || {},
          };
          const filtered = oldList.filter((c) => c.addr !== childAddr);
          return {
            ...prev,
            accountData: {
              ...prev.accountData,
              childrenData: [...filtered, updatedChild],
            },
          };
        });
      } catch (err) {
        console.error("Error loading child data:", err);
      }
    },
    [fetchFLOWBalance, fetchTSHOTBalance, fetchTopShotCollection, dispatch]
  );

  /**
   * 5g) loadChildrenData => multiple children in parallel
   */
  const loadChildrenData = useCallback(
    async (childAddresses) => {
      if (!Array.isArray(childAddresses)) return;
      dispatch({ type: "SET_LOADING_CHILDREN", payload: true });

      try {
        const results = await Promise.all(
          childAddresses.map(async (addr) => {
            const [flowBal, tshotBal, colData] = await Promise.all([
              fetchFLOWBalance(addr),
              fetchTSHOTBalance(addr),
              fetchTopShotCollection(addr),
            ]);
            return {
              addr,
              flowBalance: flowBal,
              tshotBalance: tshotBal,
              nftDetails: colData.details || [],
              hasCollection: colData.hasCollection,
              tierCounts: colData.tierCounts || {},
            };
          })
        );
        dispatch({ type: "SET_CHILDREN_DATA", payload: results });
        dispatch({ type: "SET_CHILDREN_ADDRESSES", payload: childAddresses });
      } catch (err) {
        console.error("Error loading children data:", err);
      } finally {
        dispatch({ type: "SET_LOADING_CHILDREN", payload: false });
      }
    },
    [fetchFLOWBalance, fetchTSHOTBalance, fetchTopShotCollection]
  );

  /**
   * 5h) checkForChildren => see if user has any children,
   *     then load them in parallel
   */
  const checkForChildren = useCallback(
    async (parentAddr) => {
      if (!parentAddr || !parentAddr.startsWith("0x")) return false;
      dispatch({ type: "SET_LOADING_CHILDREN", payload: true });
      try {
        const hasKids = await fcl.query({
          cadence: hasChildrenCadence,
          args: (arg, t) => [arg(parentAddr, t.Address)],
        });
        dispatch({
          type: "SET_ACCOUNT_DATA",
          payload: { hasChildren: hasKids },
        });

        if (hasKids) {
          const childAddrs = await fcl.query({
            cadence: getChildren,
            args: (arg, t) => [arg(parentAddr, t.Address)],
          });
          await loadChildrenData(childAddrs);
        } else {
          dispatch({ type: "SET_CHILDREN_ADDRESSES", payload: [] });
          dispatch({ type: "SET_CHILDREN_DATA", payload: [] });
        }
        return hasKids;
      } catch (error) {
        console.error("Error fetching children data:", error);
        return false;
      } finally {
        dispatch({ type: "SET_LOADING_CHILDREN", payload: false });
      }
    },
    [loadChildrenData, dispatch]
  );

  /**
   * 5i) loadAllUserData => parent + children
   */
  const loadAllUserData = useCallback(
    async (address) => {
      if (!address) return;
      // 1) Load metadata (optional)
      await loadTopShotMetadata();

      // 2) Parent
      await loadParentData(address);

      // 3) Children
      await checkForChildren(address);
    },
    [loadTopShotMetadata, loadParentData, checkForChildren]
  );

  /**
   * 5j) Subscribe to FCL user (login events)
   */
  useEffect(() => {
    const unsub = fcl.currentUser.subscribe((currentUser) => {
      dispatch({ type: "SET_USER", payload: currentUser });
      if (!currentUser?.loggedIn) {
        dispatch({ type: "RESET_STATE" });
        setDidLoad(false);
      }
    });
    return () => unsub();
  }, [dispatch]);

  /**
   * 5k) On user login, load data once
   */
  useEffect(() => {
    const { loggedIn, addr } = state.user || {};
    if (loggedIn && addr && !didLoad) {
      setDidLoad(true);
      dispatch({
        type: "SET_ACCOUNT_DATA",
        payload: { parentAddress: addr },
      });
      loadAllUserData(addr).catch((err) => console.error(err));
    }
  }, [state.user, didLoad, loadAllUserData, dispatch]);

  /**
   * 5l) Poll parent's FLOW/TSHOT every 60s
   */
  useEffect(() => {
    const { loggedIn, addr } = state.user || {};
    if (!loggedIn || !addr) return;

    const timer = setInterval(async () => {
      try {
        const flowBal = await fetchFLOWBalance(addr);
        const tshotBal = await fetchTSHOTBalance(addr);
        dispatch({
          type: "SET_ACCOUNT_DATA",
          payload: { flowBalance: flowBal, tshotBalance: tshotBal },
        });
      } catch (err) {
        console.error("Polling refresh error:", err);
      }
    }, 60000);

    return () => clearInterval(timer);
  }, [state.user, fetchFLOWBalance, fetchTSHOTBalance, dispatch]);

  // Dev logging
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("UserContext State:", state);
    }
  }, [state]);

  /**
   * 5m) Expose context value
   */
  const contextValue = useMemo(
    () => ({
      ...state,
      dispatch,
      logIn,
      logOut,
      loadAllUserData,
      loadParentData,
      loadChildData,
      refreshMetadataNow: async () => {
        await fetchRemoteTopShotMetadata();
        if (state.user?.addr) {
          loadAllUserData(state.user.addr);
        }
      },
    }),
    [
      state,
      dispatch,
      logIn,
      logOut,
      loadAllUserData,
      loadParentData,
      loadChildData,
    ]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
