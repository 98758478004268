// pages/TopShot.js
import React, { useState, useEffect, createContext } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import TopShotLogo from "../logos/TopShot-white.svg";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import _ from "lodash";

export const TopShotDataContext = createContext(null);

const CACHE_KEY = "topshot_data_cache";
const CACHE_DURATION = 5 * 60 * 1000; // 5 mins

function loadCache() {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
  } catch (e) {
    console.error("Error loading cache:", e);
  }
  return null;
}

function saveCache(data) {
  try {
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({ data, timestamp: Date.now() })
    );
  } catch (e) {
    console.error("Error saving cache:", e);
  }
}

/**
 * Example multi-tier detection => "mixed"
 */
function processTopShotData(series, sets, tiers) {
  const processedTiers = {};

  tiers.forEach((item) => {
    const defaultTier = item.defaultTier?.toLowerCase();
    if (defaultTier === "none") {
      if (item.playTiers?.length) {
        const unique = _.chain(item.playTiers)
          .map((p) => p.tier?.toLowerCase())
          .filter((t) => t && t !== "none")
          .uniq()
          .value();

        if (unique.length === 0) {
          processedTiers[item.setID] = "unknown";
        } else if (unique.length === 1) {
          processedTiers[item.setID] = unique[0];
        } else {
          processedTiers[item.setID] = "mixed";
        }
      } else {
        processedTiers[item.setID] = "unknown";
      }
    } else {
      // defaultTier is not "none"
      if (item.playTiers?.length) {
        const extra = _.chain(item.playTiers)
          .map((p) => p.tier?.toLowerCase())
          .filter((t) => t && t !== "none" && t !== defaultTier)
          .uniq()
          .value();

        if (extra.length === 0) {
          processedTiers[item.setID] = defaultTier || "unknown";
        } else {
          processedTiers[item.setID] = "mixed";
        }
      } else {
        processedTiers[item.setID] = defaultTier || "unknown";
      }
    }
  });

  return {
    series: series.sort((a, b) => a.id - b.id),
    sets,
    tiers: processedTiers,
  };
}

const TopShot = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [topShotData, setTopShotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const init = async () => {
      const cached = loadCache();
      if (cached) {
        setTopShotData(cached);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const [resSeries, resSets, resTiers] = await Promise.all([
          fetch("https://api.vaultopolis.com/topshot-series"),
          fetch("https://api.vaultopolis.com/topshot-sets"),
          fetch("https://api.vaultopolis.com/topshot-tiers"),
        ]);
        const [series, sets, tiers] = await Promise.all([
          resSeries.json(),
          resSets.json(),
          resTiers.json(),
        ]);

        const processed = processTopShotData(series, sets, tiers);
        setTopShotData(processed);
        saveCache(processed);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    init();
  }, []);

  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }
  if (loading) {
    return <div className="p-4 text-center">Loading data...</div>;
  }

  // Nav tabs (Set Browser, Moment Browser, etc.)
  const navigationTabs = [
    { path: "series", label: "Set Browser" },
    { path: "moment-browser", label: "Moment Browser" },
    { path: "communities", label: "Communities" },
    { path: "gaming", label: "Gaming" },
    { path: "challenges", label: "Challenges" },
  ];

  return (
    <TopShotDataContext.Provider value={topShotData}>
      <div className="p-2">
        {/* Banner row: Stacks on mobile, row on lg */}
        <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-blue-400 via-gray-400 to-red-400 p-3 rounded-lg shadow-lg">
          {/* Link to official NBA TopShot */}
          <a
            href="https://nbatopshot.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img src={TopShotLogo} alt="NBA TopShot Logo" className="h-10" />
          </a>

          {/* Social Icons */}
          <div className="flex flex-wrap justify-center lg:justify-start items-center gap-3 mt-2 lg:mt-0 lg:ml-6">
            {[
              {
                href: "https://www.instagram.com/nbatopshot/",
                Icon: FaInstagram,
                label: "Instagram",
              },
              { href: "https://x.com/nbatopshot", Icon: FaTwitter, label: "X" },
              {
                href: "https://discord.gg/nbatopshot",
                Icon: FaDiscord,
                label: "Discord",
              },
              {
                href: "https://www.reddit.com/r/nbatopshot/",
                Icon: FaReddit,
                label: "Reddit",
              },
            ].map(({ href, Icon, label }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
              >
                <Icon className="h-5 w-5" />
                <span className="ml-1 hidden lg:inline text-sm">{label}</span>
              </a>
            ))}
          </div>
        </div>

        {/* Top-level nav: flex-wrap so it goes to next line if needed */}
        <div className="flex flex-wrap gap-2 mt-2 mb-2 border-b-2 pb-2">
          {navigationTabs.map(({ path, label }) => {
            const isActive = location.pathname.startsWith(`/topshot/${path}`);
            return (
              <button
                key={path}
                onClick={() => navigate(`/topshot/${path}`)}
                className={`px-3 py-1 rounded-lg transition-transform duration-300 hover:scale-105 whitespace-nowrap
                  ${
                    isActive
                      ? "bg-green-500 text-white"
                      : "bg-gray-200 text-black"
                  }
                `}
              >
                {label}
              </button>
            );
          })}
        </div>

        {/* Child routes: /topshot/series, /topshot/moment-browser, etc. */}
        <Outlet />
      </div>
    </TopShotDataContext.Provider>
  );
};

export default TopShot;
