// components/MomentCard.js
import React, { useEffect, useRef } from "react";
import { getTierBackground } from "../components/TopShotHelpers";

/**
 * MomentCard displays a fixed-dimension card:
 * - 160px wide x 224px tall.
 * - 160x160 image, then 64px metadata container.
 * - If image fails to load, we swap to a placeholder.
 */
const MomentCard = ({ nft, disableHover = false }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (!cardRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target.querySelector("img[data-src]");
          if (img) {
            const realSrc = img.getAttribute("data-src");
            if (realSrc) {
              // Set the actual src so the image is loaded
              img.src = realSrc;
            }
          }
          observer.unobserve(entry.target);
        }
      });
    });
    observer.observe(cardRef.current);
    return () => observer.disconnect();
  }, []);

  // Basic fields
  const playerName = nft?.FullName || "Unknown Player";
  const playID = nft?.playID ? String(nft.playID) : "?";
  const momentCount = nft?.momentCount || "?";
  const retiredText = nft?.retired ? "Yes" : "No";
  const setID = nft?.setID;
  const setName = nft?.setName;
  const imageUrl = nft?.imageUrl || "";

  // Tier-based background from TopShotHelpers
  const cardBgClass = getTierBackground(nft?.tier);

  const containerClasses = [
    "relative",
    "border",
    "border-gray-600",
    "rounded",
    "overflow-hidden",
    "text-white",
    cardBgClass,
    "w-[160px]",
    "h-[224px]",
    "flex",
    "flex-col",
    "p-1",
    disableHover ? "" : "cursor-pointer",
  ].join(" ");

  return (
    <div ref={cardRef} className={containerClasses}>
      {/* 160x160 image container */}
      <div className="w-full h-[160px] overflow-hidden flex items-center justify-center">
        <img
          data-src={imageUrl}
          alt={`${playerName} Moment`}
          className="object-cover transform scale-[1.3]"
          style={{
            width: "100%",
            height: "100%",
            objectPosition: "center",
          }}
          onError={(e) => {
            e.currentTarget.onerror = null;
            // Optional: console.warn(`Failed to load image: ${imageUrl}`);
            e.currentTarget.src =
              "https://storage.googleapis.com/flowconnect/topshot/placeholder.jpg";
          }}
        />
      </div>

      {/* Metadata container (64px) */}
      <div className="w-full h-[64px] flex flex-col justify-evenly items-center text-[0.6rem]">
        <p className="truncate text-gray-200 w-full text-center">
          {playerName}
        </p>
        <p className="truncate text-gray-200 w-full text-center">
          Set ID: {setID != null ? setID : "?"} | Play ID: {playID}
        </p>
        <p className="truncate text-gray-200 w-full text-center">
          Retired: {retiredText}
        </p>
        <p
          className="truncate text-gray-200 w-full text-center"
          title={setName}
        >
          {setName || "Unknown Set"}
        </p>
        <p className="truncate text-gray-200 w-full text-center">
          / {momentCount}
        </p>
      </div>
    </div>
  );
};

export default MomentCard;
