// pages/MomentBrowser.js
import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import MomentCard from "../components/MomentCard";
import {
  TIER_CONFIG,
  TIER_ORDER,
  TIER_LABEL_MAP,
} from "../components/TopShotHelpers";

const MOMENTS_PER_PAGE = 100;

export default function MomentBrowser() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allMoments, setAllMoments] = useState([]);

  // Filter states
  const [seriesList, setSeriesList] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState("all");
  const [selectedTier, setSelectedTier] = useState("all");
  const [retiredFilter, setRetiredFilter] = useState("all");
  const [playerQuery, setPlayerQuery] = useState("");
  const [maxMomentCount, setMaxMomentCount] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Collapsible filter panel on mobile
  const [filtersOpen, setFiltersOpen] = useState(false);
  function toggleFilters() {
    setFiltersOpen(!filtersOpen);
  }

  // 1) Fetch data
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await fetch("https://api.vaultopolis.com/topshot-data");
        const data = await res.json();

        // Unique sorted list of series
        const s = new Set();
        data.forEach((m) => {
          if (Number.isInteger(m.series)) s.add(m.series);
        });
        const sortedSeries = [...s].sort((a, b) => a - b);

        setAllMoments(data);
        setSeriesList(sortedSeries);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // 2) Loading / Error
  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }
  if (loading) {
    return (
      <div className="p-4 flex items-center">
        <FaSpinner className="animate-spin text-2xl text-blue-500 mr-2" />
        <span>Loading all moments...</span>
      </div>
    );
  }

  // 3) Apply Filters
  let filtered = [...allMoments];

  // Series
  if (selectedSeries !== "all") {
    const numeric = Number(selectedSeries);
    filtered = filtered.filter((m) => m.series === numeric);
  }

  // Tier
  if (selectedTier !== "all") {
    filtered = filtered.filter((m) => {
      const raw = (m.tier || "").trim().toLowerCase();
      if (!raw || raw === "none") return selectedTier === "unknown";
      if (raw.includes("/")) return selectedTier === "mixed";
      return raw === selectedTier;
    });
  }

  // Retired
  if (retiredFilter !== "all") {
    const wantRetired = retiredFilter === "yes";
    filtered = filtered.filter((m) => !!m.retired === wantRetired);
  }

  // Player query
  if (playerQuery.trim()) {
    const q = playerQuery.trim().toLowerCase();
    filtered = filtered.filter((m) =>
      (m.FullName || "").toLowerCase().includes(q)
    );
  }

  // Max moment count
  if (maxMomentCount.trim()) {
    const maxVal = parseInt(maxMomentCount, 10);
    if (!isNaN(maxVal)) {
      filtered = filtered.filter((m) => m.momentCount <= maxVal);
    }
  }

  // 4) Pagination
  const totalPages = Math.ceil(filtered.length / MOMENTS_PER_PAGE);
  const safePage = Math.min(Math.max(currentPage, 1), totalPages || 1);

  const startIndex = (safePage - 1) * MOMENTS_PER_PAGE;
  const endIndex = startIndex + MOMENTS_PER_PAGE;
  const pageData = filtered.slice(startIndex, endIndex);

  function goToPage(p) {
    setCurrentPage(p);
  }
  function handlePrev() {
    if (safePage > 1) setCurrentPage(safePage - 1);
  }
  function handleNext() {
    if (safePage < totalPages) setCurrentPage(safePage + 1);
  }

  function renderPagination() {
    if (totalPages <= 1) return null;

    const pages = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else if (safePage <= 4) {
      pages.push(1, 2, 3, 4, 5, "...", totalPages);
    } else if (safePage > totalPages - 4) {
      pages.push(
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    } else {
      pages.push(
        1,
        "...",
        safePage - 1,
        safePage,
        safePage + 1,
        "...",
        totalPages
      );
    }

    return (
      <div className="flex items-center gap-2 mt-2">
        <button
          onClick={handlePrev}
          disabled={safePage <= 1}
          className="px-2 py-1 bg-gray-200 text-gray-800 rounded disabled:bg-gray-100"
        >
          Prev
        </button>
        {pages.map((p, idx) => (
          <button
            key={`page-${idx}`}
            onClick={() => p !== "..." && goToPage(Number(p))}
            className={`px-2 py-1 rounded ${
              p === safePage
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            } ${p === "..." ? "opacity-50 pointer-events-none" : ""}`}
          >
            {p}
          </button>
        ))}
        <button
          onClick={handleNext}
          disabled={safePage >= totalPages}
          className="px-2 py-1 bg-gray-200 text-gray-800 rounded disabled:bg-gray-100"
        >
          Next
        </button>
      </div>
    );
  }

  // 5) Render
  return (
    <div className="p-2">
      {/* Mobile "Filters" button */}
      <div className="flex items-center justify-end mb-2">
        <button
          className="px-3 py-1 bg-gray-700 text-white rounded md:hidden"
          onClick={toggleFilters}
          aria-expanded={filtersOpen}
          aria-controls="momentFiltersPanel"
        >
          Filters
        </button>
      </div>

      {/* FILTER PANEL */}
      <div
        id="momentFiltersPanel"
        className={`${
          filtersOpen ? "block" : "hidden"
        } md:block border rounded mb-4 p-2`}
      >
        {/* SERIES */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Series</h3>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => {
                setSelectedSeries("all");
                setCurrentPage(1);
              }}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedSeries === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "#6B7280" }}
            >
              All
            </button>
            {seriesList.map((num) => (
              <button
                key={num}
                onClick={() => {
                  setSelectedSeries(String(num));
                  setCurrentPage(1);
                }}
                className={`px-3 py-1 rounded text-white text-sm ${
                  selectedSeries === String(num)
                    ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                    : ""
                }`}
                style={{ backgroundColor: "#6B7280" }}
              >
                {num}
              </button>
            ))}
          </div>
        </div>

        {/* TIER */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Tier</h3>
          <div className="flex flex-wrap gap-2">
            {/* "All" tier */}
            <button
              onClick={() => {
                setSelectedTier("all");
                setCurrentPage(1);
              }}
              className={`px-3 py-1 rounded text-white text-sm ${
                selectedTier === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "#6B7280" }}
            >
              All
            </button>
            {TIER_ORDER.map((tierKey) => {
              const tierObj = TIER_CONFIG[tierKey] || TIER_CONFIG.unknown;
              const gradientClass = `${tierObj.background.gradient.class} ${tierObj.background.gradient.bg} text-white`;
              const isSelected =
                selectedTier === tierKey
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : "";
              return (
                <button
                  key={tierKey}
                  onClick={() => {
                    setSelectedTier(tierKey);
                    setCurrentPage(1);
                  }}
                  className={`px-3 py-1 rounded text-sm ${isSelected} ${gradientClass}`}
                >
                  {tierObj.label}
                </button>
              );
            })}
          </div>
        </div>

        {/* RETIRED */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Retired</h3>
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => {
                setRetiredFilter("all");
                setCurrentPage(1);
              }}
              className={`px-3 py-1 rounded text-white text-sm ${
                retiredFilter === "all"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "#6B7280" }}
            >
              All
            </button>
            <button
              onClick={() => {
                setRetiredFilter("yes");
                setCurrentPage(1);
              }}
              className={`px-3 py-1 rounded text-white text-sm ${
                retiredFilter === "yes"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "green" }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                setRetiredFilter("no");
                setCurrentPage(1);
              }}
              className={`px-3 py-1 rounded text-white text-sm ${
                retiredFilter === "no"
                  ? "ring-4 ring-green-500 ring-offset-2 ring-offset-gray-100"
                  : ""
              }`}
              style={{ backgroundColor: "red" }}
            >
              No
            </button>
          </div>
        </div>

        {/* Player Name */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Player Name</h3>
          <input
            type="text"
            value={playerQuery}
            onChange={(e) => {
              setPlayerQuery(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Search name..."
            className="border rounded px-2 py-1 w-full md:w-60"
          />
        </div>

        {/* Max MomentCount */}
        <div className="mb-3">
          <h3 className="font-bold text-sm mb-1">Max Moment Count</h3>
          <input
            type="number"
            value={maxMomentCount}
            onChange={(e) => {
              setMaxMomentCount(e.target.value);
              setCurrentPage(1);
            }}
            placeholder="e.g. 9999"
            className="border rounded px-2 py-1 w-full md:w-40"
          />
        </div>
      </div>

      {/* Pagination & Results Info */}
      <div className="mb-2 flex flex-col md:flex-row md:justify-between md:items-center">
        <p>
          Found <strong>{filtered.length}</strong> moments total.
        </p>
        {renderPagination()}
      </div>

      {/* 
        FLEX WRAP layout with exact spacing you had:
        parent has -ml-2, each card container has ml-2 mb-2 
        => small consistent horizontal gap
      */}
      <div className="flex flex-wrap -ml-2">
        {pageData.map((m) => {
          // Determine finalTier:
          const raw = (m.tier || "").trim().toLowerCase();
          let finalTier = "unknown";
          if (!raw || raw === "none") finalTier = "unknown";
          else if (raw.includes("/")) finalTier = "mixed";
          else finalTier = raw;

          const nftObj = {
            FullName: m.FullName,
            playID: m.playID,
            tier: finalTier,
            retired: !!m.retired,
            momentCount: m.momentCount,
            imageUrl: `https://storage.googleapis.com/flowconnect/topshot/images_small/${m.setID}_${m.playID}.jpg`,
            setID: m.setID,
            setName: m.name,
          };

          return (
            <div key={`${m.setID}-${m.playID}`} className="ml-2 mb-2">
              <MomentCard nft={nftObj} disableHover />
            </div>
          );
        })}
      </div>

      {/* Bottom Pagination */}
      {renderPagination()}
    </div>
  );
}
