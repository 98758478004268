import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { getLogoUrl } from "./GenericHelpers";

const CalendarToday = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Your backend root
  const backendUrl = "https://api.vaultopolis.com";

  // Google calendars (REMOVED the "08b1fcc2.../TopShot Active Challenges" entry)
  const calendarSources = [
    {
      id: "c_47978f5cd9da636cadc6b8473102b5092c1a865dd010558393ecb7f9fd0c9ad0@group.calendar.google.com",
      className: "flow-events",
      label: "Flow Events",
      color: "#32CD32",
    },
    {
      id: "e353d875e2d6f477b3dec3f100c576764982286560a2a9ea80d45bbb80e2966c@group.calendar.google.com",
      className: "flow-community-events",
      label: "Flow Community Events",
      color: "#32CD32",
    },
    {
      id: "d8195ace6087ccdf7d1de1f340852de0b13ef95342cb0b9cc4bcff13a842f0e8@group.calendar.google.com",
      className: "nba-games",
      label: "NBA Games",
      color: "#6699ff",
    },
    {
      id: "f83c1fcb0f1a9e4900c43ad7ea7135797dfd352edba69c2869b241654463797c@group.calendar.google.com",
      className: "nfl-games",
      label: "NFL Games",
      color: "#96b6ff",
    },
  ];

  // We'll define "today" from 00:00 to 23:59
  const getTimeRange = () => {
    const today = new Date();
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    );
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );
    return {
      timeMin: startOfDay.toISOString(),
      timeMax: endOfDay.toISOString(),
    };
  };

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      // 1) Fetch Google Calendars
      const { timeMin, timeMax } = getTimeRange();
      const promises = calendarSources.map((calendar) =>
        axios.get(`${backendUrl}/api/calendar/events`, {
          params: {
            calendarId: calendar.id,
            timeMin,
            timeMax,
          },
        })
      );
      const results = await Promise.all(promises);

      // Flatten & transform
      const googleEvents = results.flatMap((result, index) =>
        result.data.map((event) => {
          const startDate = new Date(
            event.start?.dateTime || event.start?.date
          );
          const endDate = event.end
            ? new Date(event.end?.dateTime || event.end?.date)
            : null;

          return {
            summary: event.summary,
            description: event.description,
            startTime: startDate,
            endTime: endDate,
            htmlLink: event.htmlLink,
            calendarLabel: calendarSources[index].label,
            backgroundColor: calendarSources[index].color,
            logo: getLogoUrl(calendarSources[index].label),
          };
        })
      );

      // Filter out NBA Games if desired
      const excludeNBA = googleEvents.filter(
        (evt) => evt.calendarLabel !== "NBA Games"
      );
      return excludeNBA;
    };

    const fetchTopShotChallenges = async () => {
      // 2) Fetch TopShot from your new endpoint
      const res = await axios.get(`${backendUrl}/topshot-active-challenges`);
      const challenges = res.data || [];

      // We'll interpret "startTime" and "endTime" for "today" logic.
      // For a "one-day" approach, we can set startTime = now, endTime = expiration?
      const todayChallenges = challenges.map((ch) => {
        // If there's a variableChallenge.startDate, use it; otherwise "today"
        const start = ch.variableChallenge?.startDate
          ? new Date(ch.variableChallenge.startDate)
          : new Date(); // fallback to "today" if none

        // If there's an expirationDate, parse it
        const end = ch.expirationDate ? new Date(ch.expirationDate) : null;

        return {
          summary: ch.name,
          description: ch.description,
          startTime: start,
          endTime: end,
          htmlLink: null,
          calendarLabel: "TopShot Active Challenges",
          backgroundColor: "#ccccff",
          logo: getLogoUrl("TopShot Active Challenges"),
        };
      });
      return todayChallenges;
    };

    const fetchAllTodayEvents = async () => {
      try {
        const [googleEvents, topShotEvents] = await Promise.all([
          fetchGoogleEvents(),
          fetchTopShotChallenges(),
        ]);

        // Combine
        let combined = [...googleEvents, ...topShotEvents];

        // Now we only want events that occur "today."
        // We'll define "isEventToday" as startTime or endTime matching today's date.
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filtered = combined.filter((evt) => {
          // If there's no startTime, skip
          if (!evt.startTime) return false;

          // Check if the start or end is "today" by date
          const isStartToday =
            evt.startTime.toDateString() === today.toDateString();
          const isEndToday =
            evt.endTime && evt.endTime.toDateString() === today.toDateString();
          return isStartToday || isEndToday;
        });

        setEvents(filtered);
      } catch (err) {
        console.error("Error fetching today's events:", err);
      }
    };

    fetchAllTodayEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const renderEvent = (event) => {
    // Format the time (HH:MM)
    const startTimeStr = event.startTime?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Basic layout
    let content = (
      <div
        className="flex items-center mb-1"
        style={{
          backgroundColor: event.backgroundColor,
          padding: "4px",
          borderRadius: "4px",
          color: "#fff",
        }}
      >
        {event.logo && (
          <img
            src={event.logo}
            alt={event.calendarLabel}
            className="h-5 w-5 mr-2"
          />
        )}
        <span className="mr-2">{startTimeStr}</span>
        <span>{event.summary}</span>
      </div>
    );

    // Special NFL logic: parse "TEAM1 at TEAM2"
    if (event.calendarLabel === "NFL Games") {
      const delimiter = " at ";
      const [team1, team2] = (event.summary || "")
        .replace(/[^\w\s@]/g, "")
        .split(delimiter)
        .map((team) => team.trim());

      if (team1 && team2) {
        content = (
          <div
            className="flex items-center mb-1"
            style={{
              backgroundColor: event.backgroundColor,
              padding: "4px",
              borderRadius: "4px",
              color: "#fff",
            }}
          >
            <img
              src={event.logo}
              alt={event.calendarLabel}
              className="h-5 w-5 mr-2"
            />
            <span className="mr-2">{startTimeStr}</span>
            <img src={getLogoUrl(team1)} alt={team1} className="h-6 w-6 mr-2" />
            <span>at</span>
            <img src={getLogoUrl(team2)} alt={team2} className="h-6 w-6 ml-2" />
          </div>
        );
      }
    }

    return content;
  };

  return (
    <div>
      <h2 className="text-lg font-bold mb-2">Today's Events</h2>
      <ul className="space-y-1">
        {events.length === 0 ? (
          <li className="text-sm">No events today.</li>
        ) : (
          events.map((event, index) => (
            <li
              key={index}
              onClick={() => handleEventClick(event)}
              className="event-item cursor-pointer transition hover:bg-gray-200"
            >
              {renderEvent(event)}
            </li>
          ))
        )}
      </ul>

      {selectedEvent && (
        <div className="modal fixed inset-0 flex items-center justify-center">
          <div className="modal-content bg-white p-4 rounded shadow-lg">
            <h3 className="text-lg font-bold">{selectedEvent.summary}</h3>
            <p className="text-sm mb-2">
              Start:{" "}
              {selectedEvent.startTime
                ? selectedEvent.startTime.toLocaleString()
                : "N/A"}
            </p>
            {selectedEvent.endTime && (
              <p className="text-sm mb-2">
                End: {selectedEvent.endTime.toLocaleString()}
              </p>
            )}
            {selectedEvent.calendarLabel !== "NBA Games" && (
              <div
                className="text-sm mb-2"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    selectedEvent.description || "No description available"
                  ),
                }}
              />
            )}
            {selectedEvent.htmlLink && (
              <a
                href={selectedEvent.htmlLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline mt-2 block"
              >
                View Event on Google Calendar
              </a>
            )}
            <button
              onClick={closeModal}
              className="bg-red-500 text-white p-2 rounded mt-2"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarToday;
