import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { getLogoUrl } from "./GenericHelpers";

const CalendarUpcoming = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Root URL for your backend
  const backendUrl = "https://api.vaultopolis.com";

  /**
   * Remaining Google Calendar sources (REMOVED the "TopShot Active Challenges" ID)
   */
  const calendarSources = [
    {
      id: "c_47978f5cd9da636cadc6b8473102b5092c1a865dd010558393ecb7f9fd0c9ad0@group.calendar.google.com",
      label: "Flow Events",
      color: "#32CD32",
    },
    {
      id: "e353d875e2d6f477b3dec3f100c576764982286560a2a9ea80d45bbb80e2966c@group.calendar.google.com",
      label: "Flow Community Events",
      color: "#32CD32",
    },
    {
      id: "d8195ace6087ccdf7d1de1f340852de0b13ef95342cb0b9cc4bcff13a842f0e8@group.calendar.google.com",
      label: "NBA Games",
      color: "#6699ff",
    },
    {
      id: "f83c1fcb0f1a9e4900c43ad7ea7135797dfd352edba69c2869b241654463797c@group.calendar.google.com",
      label: "NFL Games",
      color: "#96b6ff",
    },
  ];

  /**
   * We'll fetch items from "today" up to 30 days in the future
   */
  const getTimeRange = () => {
    const now = new Date();
    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );
    const endRange = new Date(startOfToday);
    endRange.setDate(endRange.getDate() + 30);

    return {
      timeMin: startOfToday.toISOString(),
      timeMax: endRange.toISOString(),
    };
  };

  useEffect(() => {
    const fetchGoogleCalendarEvents = async () => {
      const { timeMin, timeMax } = getTimeRange();

      // Fetch from each calendar in parallel
      const promises = calendarSources.map((src) =>
        axios.get(`${backendUrl}/api/calendar/events`, {
          params: {
            calendarId: src.id,
            timeMin,
            timeMax,
          },
        })
      );

      const results = await Promise.all(promises);

      // Flatten and map to a consistent shape
      let googleEvents = results.flatMap((res, idx) =>
        res.data.map((event) => {
          const start = new Date(event.start?.dateTime || event.start?.date);
          const end = event.end
            ? new Date(event.end.dateTime || event.end.date)
            : null;

          return {
            summary: event.summary,
            description: event.description,
            startTime: start,
            endTime: end,
            htmlLink: event.htmlLink,
            calendarLabel: calendarSources[idx].label,
            backgroundColor: calendarSources[idx].color,
            logo: getLogoUrl(calendarSources[idx].label),
          };
        })
      );

      // Example: remove NBA Games if desired
      googleEvents = googleEvents.filter(
        (evt) => evt.calendarLabel !== "NBA Games"
      );

      return googleEvents;
    };

    const fetchTopShotChallenges = async () => {
      // Your new endpoint for active challenges
      const res = await axios.get(`${backendUrl}/topshot-active-challenges`);
      const challenges = res.data || [];

      /**
       * Convert each challenge to an "event-like" shape, so we can
       * combine it with the Google Calendar events.
       *
       * We'll guess how to interpret "startTime" and "endTime".
       * For example:
       *  - startTime = `variableChallenge.startDate` if present,
       *    or "now" if none is given
       *  - endTime = `expirationDate` if present
       */
      return challenges.map((ch) => {
        // If no variableChallenge.startDate, default to "now"
        const startDate =
          ch.variableChallenge?.startDate || new Date().toISOString();
        const expiration = ch.expirationDate; // might be null, or a date string

        return {
          summary: ch.name,
          description: ch.description,
          startTime: new Date(startDate),
          endTime: expiration ? new Date(expiration) : null,
          htmlLink: null, // or some link if you have one
          calendarLabel: "TopShot Challenges",
          backgroundColor: "#ccccff",
          logo: getLogoUrl("TopShotIcon"), // or "TopShot Active Challenges"
        };
      });
    };

    const fetchAllEvents = async () => {
      try {
        const [googleEvents, challengeEvents] = await Promise.all([
          fetchGoogleCalendarEvents(),
          fetchTopShotChallenges(),
        ]);

        // Combine them
        let allEvents = [...googleEvents, ...challengeEvents];

        // Sort by startTime ascending
        allEvents.sort((a, b) => a.startTime - b.startTime);

        // Take next 10 (upcoming)
        const nextTen = allEvents.slice(0, 10);

        setEvents(nextTen);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchAllEvents();
  }, []);

  /**
   * Handle event click -> show modal
   */
  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  /**
   * Close modal
   */
  const closeModal = () => {
    setSelectedEvent(null);
  };

  /**
   * Renders an event item in the list
   */
  const renderEvent = (event) => {
    // Format day/time
    const dateTimeStr = event.startTime.toLocaleString([], {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    // Basic styling
    let content = (
      <div
        className="flex items-center mb-1"
        style={{
          backgroundColor: event.backgroundColor,
          padding: "4px",
          borderRadius: "4px",
          color: "#fff",
        }}
      >
        {event.logo && (
          <img
            src={event.logo}
            alt={event.calendarLabel}
            className="h-5 w-5 mr-2"
          />
        )}
        <span className="mr-2">{dateTimeStr}</span>
        <span>{event.summary}</span>
      </div>
    );

    // If it's NFL, parse "TEAM1 at TEAM2"
    if (event.calendarLabel === "NFL Games") {
      const delimiter = " at ";
      const [team1, team2] = (event.summary || "")
        .replace(/[^\w\s@]/g, "")
        .split(delimiter)
        .map((team) => team.trim());

      if (team1 && team2) {
        content = (
          <div
            className="flex items-center mb-1"
            style={{
              backgroundColor: event.backgroundColor,
              padding: "4px",
              borderRadius: "4px",
              color: "#fff",
            }}
          >
            <img
              src={event.logo}
              alt={event.calendarLabel}
              className="h-5 w-5 mr-2"
            />
            <span className="mr-2">{dateTimeStr}</span>
            <img src={getLogoUrl(team1)} alt={team1} className="h-6 w-6 mr-2" />
            <span>at</span>
            <img src={getLogoUrl(team2)} alt={team2} className="h-6 w-6 ml-2" />
          </div>
        );
      }
    }

    return content;
  };

  return (
    <div>
      <h2 className="text-lg font-bold mb-2">Upcoming Events</h2>

      <ul className="space-y-1">
        {events.length === 0 ? (
          <li className="text-sm">No upcoming events found.</li>
        ) : (
          events.map((event, index) => (
            <li
              key={index}
              onClick={() => handleEventClick(event)}
              className="event-item cursor-pointer transition hover:bg-gray-200"
            >
              {renderEvent(event)}
            </li>
          ))
        )}
      </ul>

      {selectedEvent && (
        <div className="modal fixed inset-0 flex items-center justify-center">
          <div className="modal-content bg-white p-4 rounded shadow-lg">
            <h3 className="text-lg font-bold">{selectedEvent.summary}</h3>
            <p className="text-sm mb-2">
              <strong>Start:</strong> {selectedEvent.startTime.toLocaleString()}
            </p>
            {selectedEvent.endTime && (
              <p className="text-sm mb-2">
                <strong>End:</strong> {selectedEvent.endTime.toLocaleString()}
              </p>
            )}

            {/* Show description if not "NBA Games" (we already filtered them out, 
                but let's keep logic consistent) */}
            {selectedEvent.calendarLabel !== "NBA Games" && (
              <div
                className="text-sm mb-2"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    selectedEvent.description || "No description available"
                  ),
                }}
              />
            )}

            {/* Optional link if from Google Calendar */}
            {selectedEvent.htmlLink && (
              <a
                href={selectedEvent.htmlLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline mt-2 block"
              >
                View Event on Google Calendar
              </a>
            )}

            <button
              onClick={closeModal}
              className="bg-red-500 text-white p-2 rounded mt-2"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarUpcoming;
