// RSSUtilities.js

import axios from "axios";

/**
 * Fetch a single page (limit=20 by default) from /news
 * Returns { totalItems, totalPages, currentPage, items: [...] }
 */
export const fetchNewsPage = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(
      `https://api.vaultopolis.com/news?page=${page}&limit=${limit}`
    );
    return response.data; // { totalItems, totalPages, currentPage, items }
  } catch (error) {
    console.error("Error fetching news from /news:", error.message);
    return { items: [], totalPages: 1, currentPage: page };
  }
};

/**
 * Fetch all pages sequentially in the background (limit=20 by default).
 * Returns an array of *all* items combined.
 */
export const fetchAllNewsInBackground = async (limit = 20) => {
  const firstPageData = await fetchNewsPage(1, limit);
  let allItems = firstPageData.items || [];
  const totalPages = firstPageData.totalPages || 1;

  for (let p = 2; p <= totalPages; p++) {
    const pageData = await fetchNewsPage(p, limit);
    allItems = [...allItems, ...(pageData.items || [])];
  }

  return allItems; // combined array from all pages
};

/**
 * A loop-based function to fetch *all* pages if limit is not provided.
 * Similar to fetchAllNewsInBackground, but returns {items, totalItems}.
 * You can remove or keep if you prefer the other approach.
 */
export const fetchNewsFromApi = async (limit = 10, startPage = 1) => {
  try {
    let allItems = [];
    let currentPage = startPage;
    let totalPages = 1;

    do {
      const response = await axios.get(
        `https://api.vaultopolis.com/news?limit=${limit}&page=${currentPage}`
      );
      const data = response.data;

      if (data && data.items) {
        allItems = [...allItems, ...data.items];
        totalPages = data.totalPages;
        currentPage++;
      } else {
        break;
      }
    } while (currentPage <= totalPages);

    return {
      items: allItems,
      totalItems: allItems.length,
    };
  } catch (error) {
    console.error("Error fetching news from /news endpoint:", error.message);
    return { items: [], totalItems: 0 };
  }
};

/**
 * Fetch the 5 most recent items from /news
 */
export const fetchLatestNews = async () => {
  try {
    const response = await axios.get(
      `https://api.vaultopolis.com/news?limit=5`
    );
    const data = response.data;

    if (data && data.items && data.items.length > 0) {
      return data.items;
    } else {
      throw new Error("No news items available");
    }
  } catch (error) {
    console.error("Error fetching the latest news from /news:", error.message);
    return [];
  }
};

/**
 * Utility: shorten text
 */
export const shortenText = (text = "", maxLength = 100) => {
  if (!text) return "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

/**
 * Utility: capitalize first letter
 */
export const capitalizeFirstLetter = (text = "") => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Utility: map RSS source name/URL to a nicer label
 */
export const getSourceLabel = (source, url) => {
  const urlToSourceMap = {
    "meetdapper.com": "DapperLabs",
    "onflow.org": "OnFlow",
    "flow.com": "Flow",
    "nbatopshot.com": "TopShot",
    "nflallday.com": "NFL AllDay",
    "ufcstrike.com": "UFC Strike",
    "laligagolazos.com": "LaLiga Golazos",
  };

  const sourceNameMap = {
    "Collectin&Connectin": "Collectin & Connectin",
    "Diamond Dashboards": "Diamond Dashboards",
    "Own The Moment": "Own The Moment",
    Flowverse: "Flowverse",
    FanCraze: "FanCraze",
    TandyTime: "TandyTime",
    PlayMFL: "PlayMFL",
    FindLabs: "FindLabs",
    Cryptoys: "Cryptoys",
    "NLL Council": "NLL Council",
  };

  if (source === "DapperLabs") {
    for (const [urlFragment, label] of Object.entries(urlToSourceMap)) {
      if (url.includes(urlFragment)) {
        return label;
      }
    }
    return "DapperLabs";
  }

  return sourceNameMap[source] || source || "Unknown Source";
};
