import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from "axios";
import DOMPurify from "dompurify";
import { getLogoUrl } from "./GenericHelpers";

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeSource, setActiveSource] = useState("All");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const calendarRef = useRef(null);
  const dropdownRef = useRef(null);

  // Remove the old "08b1fcc..." entry for TopShot from here.
  // We'll fetch TopShot challenges separately from your new endpoint instead.
  const calendarSources = [
    {
      id: "d8195ace6087ccdf7d1de1f340852de0b13ef95342cb0b9cc4bcff13a842f0e8@group.calendar.google.com",
      className: "nba-games",
      label: "NBA Games",
      logo: getLogoUrl("NBAIcon"),
      color: "#6699ff",
    },
    {
      id: "f83c1fcb0f1a9e4900c43ad7ea7135797dfd352edba69c2869b241654463797c@group.calendar.google.com",
      className: "nfl-games",
      label: "NFL Games",
      logo: getLogoUrl("NFLIcon"),
      color: "#96b6ff",
    },
    {
      id: "c_47978f5cd9da636cadc6b8473102b5092c1a865dd010558393ecb7f9fd0c9ad0@group.calendar.google.com",
      className: "flow-events",
      label: "Flow Events",
      logo: getLogoUrl("Flow"),
      color: "#32CD32",
    },
    // If you also have "Flow Community Events", add it here
  ];

  const getTimeRange = () => {
    const now = new Date();
    // Example range: 1 month in the past, 3 months in the future
    const timeMinDate = new Date(now);
    timeMinDate.setMonth(now.getMonth() - 1);
    const timeMaxDate = new Date(now);
    timeMaxDate.setMonth(now.getMonth() + 3);

    return {
      timeMin: timeMinDate.toISOString(),
      timeMax: timeMaxDate.toISOString(),
    };
  };

  useEffect(() => {
    // 1) Fetch from Google Calendars
    const fetchGoogleEvents = async () => {
      const { timeMin, timeMax } = getTimeRange();
      const googlePromises = calendarSources.map((calendar) =>
        axios.get("https://api.vaultopolis.com/api/calendar/events", {
          params: {
            calendarId: calendar.id,
            timeMin,
            timeMax,
          },
        })
      );
      const results = await Promise.all(googlePromises);

      // Flatten & transform to FullCalendar event objects
      let allGoogleEvents = results.flatMap((res, index) =>
        res.data.map((item) => ({
          title: item.summary,
          start: item.start.dateTime || item.start.date,
          end: item.end?.dateTime || item.end?.date || null,
          description: item.description || "No description",
          url: item.htmlLink,
          className: calendarSources[index].className,
          backgroundColor: calendarSources[index].color,
          borderColor: calendarSources[index].color,
          textColor: "#fff",
          extendedProps: {
            source: calendarSources[index].label,
            logo: calendarSources[index].logo,
          },
        }))
      );

      // Filter out NBA Games if desired
      allGoogleEvents = allGoogleEvents.filter(
        (evt) => evt.extendedProps.source !== "NBA Games"
      );

      return allGoogleEvents;
    };

    // 2) Fetch from TopShot Active Challenges Endpoint
    const fetchTopShotChallenges = async () => {
      try {
        const res = await axios.get(
          "https://api.vaultopolis.com/topshot-active-challenges"
        );
        const challenges = res.data || [];

        // Convert each challenge to a FullCalendar event-like object
        return challenges.map((ch) => {
          const startDate =
            ch.variableChallenge?.startDate || new Date().toISOString();
          const endDate = ch.expirationDate
            ? new Date(ch.expirationDate).toISOString()
            : null;
          return {
            title: ch.name,
            start: startDate,
            end: endDate,
            description: ch.description || "No description",
            url: null, // or link if you have it
            className: "topshot-active-challenges",
            backgroundColor: "#ccccff",
            borderColor: "#ccccff",
            textColor: "#fff",
            extendedProps: {
              source: "TopShot Active Challenges",
              logo: getLogoUrl("TopShotIcon"),
            },
          };
        });
      } catch (err) {
        console.error("Error fetching TopShot challenges:", err);
        return [];
      }
    };

    const fetchAllEvents = async () => {
      try {
        const [googleEvents, topshotEvents] = await Promise.all([
          fetchGoogleEvents(),
          fetchTopShotChallenges(),
        ]);

        // Combine them
        let combinedEvents = [...googleEvents, ...topshotEvents];

        // 3) If multi-day challenges from TS, optionally split them up
        //    (only if you want the "Start/End" separate)
        //    If you don't want that logic, remove the below code block.
        combinedEvents = combinedEvents.flatMap((ev) => {
          if (
            ev.extendedProps.source === "TopShot Active Challenges" &&
            ev.start &&
            ev.end &&
            new Date(ev.start).toDateString() !==
              new Date(ev.end).toDateString()
          ) {
            return [
              { ...ev, title: `${ev.title} (Start)`, end: ev.start },
              { ...ev, title: `${ev.title} (End)`, start: ev.end },
            ];
          }
          return [ev];
        });

        setEvents(combinedEvents);
      } catch (error) {
        console.error("Error fetching combined calendar events:", error);
      }
    };

    fetchAllEvents();

    // Handle resizing for mobile vs. desktop view
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        const newView = window.innerWidth < 768 ? "dayGridDay" : "dayGridWeek";
        if (calendarApi.view.type !== newView) {
          calendarApi.changeView(newView);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close the dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * On event click, open modal
   */
  const handleEventClick = (info) => {
    info.jsEvent.preventDefault();
    setSelectedEvent({
      title: info.event.title || "No title",
      description: sanitizeDescription(info.event.extendedProps.description),
      start: info.event.start,
      end: info.event.end,
      source: info.event.extendedProps.source,
      logo: info.event.extendedProps.logo,
      url: info.event.url,
    });
  };

  /**
   * Dangerously set the description (cleaned with DOMPurify)
   */
  const sanitizeDescription = (description) => {
    return DOMPurify.sanitize(description, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
    });
  };

  /**
   * Filter by source (All, NFL, Flow, etc.)
   */
  const filterEvents = (source) => {
    setActiveSource(source);
    setIsDropdownOpen(false);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  /**
   * If "All", show all events; else filter by extendedProps.source
   */
  const filteredEvents =
    activeSource === "All"
      ? events
      : events.filter((ev) => ev.extendedProps.source === activeSource);

  /**
   * Renders each event block in the calendar
   */
  const renderEventContent = (eventInfo) => {
    const { source } = eventInfo.event.extendedProps;

    // Find color from the event itself or fallback
    const sourceColor =
      eventInfo.event.backgroundColor ||
      events.find((e) => e.extendedProps.source === source)?.backgroundColor ||
      "#000";

    // Default content (non-NFL)
    let content = (
      <div
        className="flex items-center"
        style={{
          backgroundColor: sourceColor,
          color: "#fff",
          padding: "2px",
          borderRadius: "4px",
          height: "40px",
        }}
      >
        <img src={getLogoUrl(source)} alt={source} className="h-6 w-6 mr-2" />
        <span>
          {eventInfo.timeText} {eventInfo.event.title}
        </span>
      </div>
    );

    // Special NFL layout
    if (source === "NFL Games") {
      const delimiter = " at ";
      const [team1, team2] = eventInfo.event.title
        .replace(/[^\w\s@]/g, "")
        .split(delimiter)
        .map((team) => team.trim());

      if (team1 && team2) {
        content = (
          <div
            className="flex items-center"
            style={{
              height: "40px",
            }}
          >
            <span className="mr-2">{eventInfo.timeText}</span>
            <img src={getLogoUrl(team1)} alt={team1} className="h-8 w-8 mr-2" />
            <span className="mr-2">at</span>
            <img src={getLogoUrl(team2)} alt={team2} className="h-8 w-8 ml-2" />
          </div>
        );
      }
    }

    return content;
  };

  return (
    <div>
      <h2 className="text-2xl text-left font-bold text-gray-800 mb-2">
        Upcoming Events
      </h2>

      {/* Source Filter Dropdown */}
      <div className="flex justify-center space-x-4 mb-6 relative">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="bg-gray-200 p-2 rounded flex items-center"
        >
          {activeSource !== "All" && (
            <img
              src={getLogoUrl(
                calendarSources.find((src) => src.label === activeSource)
                  ?.label || activeSource
              )}
              alt={activeSource}
              className="h-4 w-4 mr-2"
            />
          )}
          {activeSource === "All" ? "All Events" : activeSource}
          <span className="ml-2">&#9662;</span>
        </button>
        {isDropdownOpen && (
          <ul
            ref={dropdownRef}
            className="absolute bg-white border border-gray-300 rounded shadow-lg mt-2 w-48 z-10"
          >
            <li
              onClick={() => filterEvents("All")}
              className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <span className="ml-2">All Events</span>
            </li>
            {/* Add an extra "TopShot Active Challenges" item in the dropdown */}
            <li
              onClick={() => filterEvents("TopShot Active Challenges")}
              className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img
                src={getLogoUrl("TopShot Active Challenges")}
                alt="TopShot"
                className="h-4 w-4 mr-2"
              />
              <span>TopShot Active Challenges</span>
            </li>
            {calendarSources.map((source) => (
              <li
                key={source.id}
                onClick={() => filterEvents(source.label)}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
              >
                <img
                  src={getLogoUrl(source.label)}
                  alt={source.label}
                  className="h-4 w-4 mr-2"
                />
                <span>{source.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* FullCalendar */}
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView={isMobile ? "dayGridDay" : "dayGridWeek"}
        events={filteredEvents}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: isMobile ? "" : "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        height="auto"
        eventDisplay="block"
      />

      {/* Modal for Selected Event */}
      {selectedEvent && (
        <div className="modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="modal-content bg-white p-4 rounded shadow-lg w-4/5 max-w-2xl">
            <div className="flex items-center">
              <img
                src={getLogoUrl(selectedEvent.source)}
                alt={selectedEvent.source}
                className="h-8 w-8 mr-2"
              />
              <h2 className="text-xl font-bold">{selectedEvent.title}</h2>
            </div>
            <p>
              <strong>Start:</strong>{" "}
              {selectedEvent.start
                ? new Date(selectedEvent.start).toLocaleString()
                : "No start time"}
            </p>
            {selectedEvent.end && (
              <p>
                <strong>End:</strong>{" "}
                {new Date(selectedEvent.end).toLocaleString()}
              </p>
            )}
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: selectedEvent.description,
              }}
            />
            {selectedEvent.url && (
              <a
                href={selectedEvent.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-blue-500 hover:text-blue-700 mt-4"
              >
                View Event on Google Calendar
              </a>
            )}
            <button
              onClick={closeModal}
              className="mt-4 bg-red-500 text-white p-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
