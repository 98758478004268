import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Events from "./pages/Events";
import News from "./pages/News";
import MomentBrowser from "./pages/MomentBrowser";

// TopShot + child routes
import TopShot from "./pages/TopShot";
import TopShotSeries from "./pages/TopShotSeries";
import TopShotSets from "./pages/TopShotSets";
import TopShotCommunities from "./pages/TopShotCommunities";
import TopShotGaming from "./pages/TopShotGaming";
import TopShotChallenges from "./pages/TopShotChallenges";
import RacingGame from "./components/RacingGame";

import { UserProvider } from "./context/UserProvider";

import "./App.css";

function App() {
  const location = useLocation();

  // Optional Google Analytics snippet
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-X7QKSZ0G7R";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-X7QKSZ0G7R');
    `;
    document.head.appendChild(script2);
  }, []);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-X7QKSZ0G7R", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <UserProvider>
      <div className="flex flex-col min-h-screen bg-cover bg-center">
        <Header />

        <main className="flex-grow transition-opacity duration-300">
          <Routes>
            {/* Main site routes */}
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/events" element={<Events />} />

            {/* TopShot (parent layout) */}
            <Route path="/topshot" element={<TopShot />}>
              {/* If /topshot is visited, redirect to /topshot/series/all */}
              <Route index element={<Navigate to="series/all" replace />} />

              <Route path="series">
                {/* If /topshot/series => /topshot/series/all */}
                <Route index element={<Navigate to="all" replace />} />
                <Route path=":seriesId" element={<TopShotSeries />} />
              </Route>

              <Route path="sets/:setId" element={<TopShotSets />} />
              <Route path="moment-browser" element={<MomentBrowser />} />
              <Route path="communities" element={<TopShotCommunities />} />
              <Route path="gaming" element={<TopShotGaming />} />
              <Route path="challenges" element={<TopShotChallenges />} />
            </Route>

            {/* 404 or others if needed */}
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </main>

        <Footer />
      </div>
    </UserProvider>
  );
}

export default App;
