import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * Hard-coded known sources for filtering.
 * Must match the EXACT string your server "source" field uses.
 */
const KNOWN_SOURCES = [
  "Increment Finance",
  "FindLabs",
  "NLL Council",
  "PlayMFL",
  "Cryptoys",
  "Diamond Dashboards",
  "Own The Moment",
  "FanCraze",
  "Collectin & Connectin",
  "The First Mint",
  "Flowverse",
  "TandyTime",
  "Flow",
  "OnFlow",
  "DapperLabs",
  "UFC Strike",
  "LaLiga Golazos",
  "NBA Top Shot",
  "NFL All Day",
];

const News = () => {
  // Current page of data from the server
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // Filters
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSources, setSelectedSources] = useState([]);

  // We'll fetch 10 items per page
  const limit = 10;

  // MAIN fetch function calling your Express: /news?search=...&source=...&page=...&limit=...
  const fetchNewsFromServer = async (pageNum) => {
    setIsLoading(true);
    try {
      // Build query params
      const params = new URLSearchParams();
      params.append("page", pageNum);
      params.append("limit", limit);

      if (searchTerm.trim()) {
        params.append("search", searchTerm.trim());
      }
      if (selectedSources.length > 0) {
        // e.g. source=Flow,OnFlow
        params.append("source", selectedSources.join(","));
      }

      // GET /news?search=...&source=...&page=...&limit=...
      const response = await axios.get(
        `https://api.vaultopolis.com/news?${params.toString()}`
      );

      const data = response.data; // { totalItems, totalPages, currentPage, items }
      setItems(data.items || []);
      setTotalPages(data.totalPages || 1);
      setCurrentPage(data.currentPage || pageNum);
    } catch (error) {
      console.error("Error fetching news from server:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // On mount, load page 1
  useEffect(() => {
    fetchNewsFromServer(1);
    // eslint-disable-next-line
  }, []);

  // If user changes searchTerm or selectedSources, reset to page=1 and refetch
  useEffect(() => {
    fetchNewsFromServer(1);
    // eslint-disable-next-line
  }, [searchTerm, selectedSources]);

  // Pagination
  const handlePrev = () => {
    if (currentPage > 1) {
      fetchNewsFromServer(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      fetchNewsFromServer(currentPage + 1);
    }
  };

  // Toggle source filter
  const toggleSource = (src) => {
    if (selectedSources.includes(src)) {
      setSelectedSources(selectedSources.filter((s) => s !== src));
    } else {
      setSelectedSources([...selectedSources, src]);
    }
  };

  // Clear filters
  const clearFilters = () => {
    setSearchTerm("");
    setSelectedSources([]);
  };

  return (
    <div className="max-w-5xl mx-auto p-4">
      {/* Search Bar */}
      <div className="flex space-x-2 mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded-l-md w-64"
        />
        <button
          onClick={() => fetchNewsFromServer(1)}
          className="bg-blue-600 text-white px-4 py-2 rounded-r-md"
        >
          Search
        </button>
      </div>

      {/* Filter by Source */}
      <details className="mb-4 bg-gray-100 p-4 rounded-md shadow-sm">
        <summary className="cursor-pointer font-semibold text-gray-700">
          Filter by Source
        </summary>
        <div className="flex flex-wrap mt-4">
          {KNOWN_SOURCES.map((src) => (
            <label key={src} className="mr-4 mb-2 flex items-center">
              <input
                type="checkbox"
                checked={selectedSources.includes(src)}
                onChange={() => toggleSource(src)}
                className="mr-2"
              />
              <span
                className={`px-3 py-1 rounded-md text-sm ${
                  selectedSources.includes(src)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {src}
              </span>
            </label>
          ))}
        </div>
        {(searchTerm || selectedSources.length > 0) && (
          <button
            onClick={clearFilters}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Clear All
          </button>
        )}
      </details>

      {/* Main Content */}
      {isLoading ? (
        <SkeletonList count={limit} />
      ) : items.length === 0 ? (
        <p className="text-center text-gray-500">
          No news found for your filters.
        </p>
      ) : (
        <ul className="space-y-4">
          {items.map((item) => (
            <li
              key={item.uniqueId}
              className="bg-white shadow-lg rounded-md border border-gray-200 p-4 flex flex-col md:flex-row"
            >
              {/* Image */}
              {item.image ? (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 w-full md:w-1/3 md:mr-4 mb-2 md:mb-0"
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-40 object-cover rounded-md"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src =
                        "https://via.placeholder.com/150?text=No+Image";
                    }}
                  />
                </a>
              ) : (
                <div className="w-full md:w-1/3 md:mr-4 h-40 bg-gray-200 flex items-center justify-center rounded-md mb-2 md:mb-0">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}

              {/* Text Content */}
              <div className="md:w-2/3">
                <p className="text-sm text-gray-500">{item.source}</p>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-lg font-semibold text-blue-600 hover:underline"
                >
                  {item.title}
                </a>
                <p className="text-gray-500 text-sm">
                  {item.publishedDate
                    ? new Date(item.publishedDate).toLocaleString()
                    : "No date"}
                </p>
                {item.description &&
                  item.description !== "No description available." && (
                    <p className="mt-2 text-sm text-gray-700">
                      {item.description.substring(0, 200)}...
                    </p>
                  )}
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center items-center space-x-4 mt-6">
        <button
          onClick={handlePrev}
          disabled={currentPage <= 1}
          className={`px-4 py-2 rounded-md ${
            currentPage <= 1
              ? "bg-gray-300 text-gray-600"
              : "bg-blue-600 text-white"
          }`}
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage >= totalPages}
          className={`px-4 py-2 rounded-md ${
            currentPage >= totalPages
              ? "bg-gray-300 text-gray-600"
              : "bg-blue-600 text-white"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

function SkeletonList({ count }) {
  return (
    <ul className="space-y-4">
      {[...Array(count)].map((_, i) => (
        <li
          key={i}
          className="bg-white shadow rounded-md p-4 flex flex-col md:flex-row"
        >
          <div className="w-full md:w-1/3 md:mr-4">
            <Skeleton height={160} />
          </div>
          <div className="md:w-2/3 mt-2 md:mt-0">
            <Skeleton width="70%" />
            <Skeleton width="50%" />
            <Skeleton count={2} />
          </div>
        </li>
      ))}
    </ul>
  );
}

export default News;
