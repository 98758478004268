// src/components/RacingGame.jsx

import React, { useContext, useEffect, useRef } from "react";
import Phaser from "phaser";
import { UserContext } from "../context/UserProvider";

export default function RacingGame() {
  // Grab user + NFT data from UserContext
  const { user, accountData } = useContext(UserContext);

  const phaserRef = useRef(null); // holds Phaser.Game instance
  const containerRef = useRef(null); // ref to our <div> container for Phaser

  // Example: use parent's NFT count to control speed
  const parentNFTCount = accountData?.nftDetails?.length || 0;
  // If you want to include children, sum them:
  // const childNFTCount = accountData.childrenData.reduce((sum, child) => sum + child.nftDetails.length, 0)
  // const totalNFTCount = parentNFTCount + childNFTCount
  const totalNFTCount = parentNFTCount;

  // Example formula: base speed 100 + (10 * totalNFTCount)
  const carSpeed = 100 + totalNFTCount * 10;

  // 1) Phaser Scene defined inline
  class RacingScene extends Phaser.Scene {
    constructor() {
      super({ key: "RacingScene" });
      this.car = null;
      this.finishLineX = 600;
      this.raceStarted = false;
      this.startTime = 0;
      this.endTime = 0;
      this.baseSpeed = 100; // default
    }

    preload() {
      // No external assets needed
    }

    create() {
      // Draw a green rectangle to represent the "car"
      // (x=100, y=100, width=60, height=30, color=0x00ff00)
      this.car = this.add.rectangle(100, 100, 60, 30, 0x00ff00);
      this.car.setOrigin(0.5); // center-based origin

      // "START RACE" button (simple text)
      const startText = this.add.text(350, 150, "START RACE", {
        fill: "#000",
        backgroundColor: "#fff",
        padding: { x: 10, y: 10 },
        fontSize: "20px",
        cursor: "pointer",
      });
      startText.setInteractive();
      startText.on("pointerdown", () => {
        if (!this.raceStarted) {
          this.raceStarted = true;
          this.startTime = this.time.now;
          this.endTime = 0;
          this.updateRaceInfo("Race in progress...");
        }
      });

      // Red finish line
      this.add.line(this.finishLineX, 0, 0, 0, 0, 200, 0xff0000).setOrigin(0);

      // Initial race info
      this.updateRaceInfo("Click START RACE to begin!");
    }

    update(time, delta) {
      if (this.raceStarted) {
        // Move the "car" by baseSpeed px/s
        const distance = this.baseSpeed * (delta / 1000);
        this.car.x += distance;

        if (this.car.x >= this.finishLineX) {
          this.raceStarted = false;
          this.endTime = this.time.now;
          const durationSec = ((this.endTime - this.startTime) / 1000).toFixed(
            2
          );
          this.updateRaceInfo(
            `Finished in ${durationSec}s! Speed: ${this.baseSpeed}px/s`
          );
        }
      }
    }

    // Called externally to set speed
    setCarSpeed(speed) {
      this.baseSpeed = speed;
    }

    updateRaceInfo(message) {
      if (!this.raceInfoText) {
        this.raceInfoText = this.add.text(10, 10, message, {
          fill: "#000",
          fontSize: "16px",
          backgroundColor: "#fff",
          padding: { x: 5, y: 5 },
        });
      } else {
        this.raceInfoText.setText(message);
      }
    }
  }

  // 2) Initialize Phaser on first render
  useEffect(() => {
    if (!phaserRef.current && containerRef.current) {
      const config = {
        type: Phaser.AUTO,
        width: 800,
        height: 200,
        parent: containerRef.current,
        scene: [RacingScene],
      };
      phaserRef.current = new Phaser.Game(config);
    }

    // Cleanup on unmount
    return () => {
      if (phaserRef.current) {
        phaserRef.current.destroy(true);
        phaserRef.current = null;
      }
    };
  }, []);

  // 3) Whenever user or NFT count changes, update car speed
  useEffect(() => {
    if (phaserRef.current) {
      const scene = phaserRef.current.scene.getScene("RacingScene");
      if (scene?.setCarSpeed) {
        // If not logged in, fallback to default
        scene.setCarSpeed(user?.loggedIn ? carSpeed : 100);
      }
    }
  }, [carSpeed, user]);

  return (
    <div className="m-4 p-4 bg-white rounded shadow-md text-center">
      <h2 className="text-xl font-bold mb-2">Phaser Racing Game</h2>

      {user?.loggedIn ? (
        <p className="text-gray-700">
          You own {totalNFTCount} Top Shot NFTs. Car speed: {carSpeed}px/s.
        </p>
      ) : (
        <p className="text-red-600">Please connect your Flow wallet.</p>
      )}

      {/* Container for Phaser */}
      <div
        ref={containerRef}
        className="border border-gray-300 mt-4 inline-block"
      />
    </div>
  );
}
