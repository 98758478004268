// pages/TopShotSets.js
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TopShotDataContext } from "./TopShot";
import { FaSpinner } from "react-icons/fa";
import { parseUnicode } from "../components/GenericHelpers";
import MomentCard from "../components/MomentCard";

const TopShotSets = () => {
  const { setId } = useParams();
  const navigate = useNavigate();
  const topShotData = useContext(TopShotDataContext);
  const { user, nftCollection } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editions, setEditions] = useState([]);
  const [tierMap, setTierMap] = useState({});
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch("https://api.vaultopolis.com/topshot-data");
        const data = await res.json();

        // Filter to the chosen setID
        const filtered = data
          .filter((item) => item.setID === Number(setId))
          .sort((a, b) => a.playOrder - b.playOrder);

        setEditions(filtered);

        // Build tier & image maps
        const tMap = {};
        const iMap = {};

        filtered.forEach((edition) => {
          let rawTier = (edition.tier || "").trim().toLowerCase();
          if (!rawTier || rawTier === "none") rawTier = "unknown";
          tMap[edition.playID] = rawTier;

          iMap[
            edition.playID
          ] = `https://storage.googleapis.com/flowconnect/topshot/images_small/${edition.setID}_${edition.playID}.jpg`;
        });

        setTierMap(tMap);
        setImageUrls(iMap);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setId]);

  // "currentSet" from your topShotData context
  const currentSet = topShotData?.sets.find((s) => s.id === Number(setId));

  if (error) {
    return <div className="p-8 text-red-500">Error: {error}</div>;
  }
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <span className="ml-2 text-xl">Loading...</span>
      </div>
    );
  }
  if (!currentSet) {
    return (
      <div className="p-8 text-red-500">
        Could not find set with ID {setId}.
      </div>
    );
  }

  return (
    <div className="p-2">
      {/* Back button */}
      <button
        onClick={() => navigate(`/topshot/series/${currentSet.series}`)}
        className="mb-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
      >
        ← Back
      </button>

      {/* Display set info + locked/open badge */}
      <h1 className="text-xl font-bold mb-2 flex items-center space-x-2">
        <span>
          Series {currentSet.series} - {parseUnicode(currentSet.name || "")}
        </span>
        {currentSet.locked ? (
          <span className="px-2 py-1 text-xs rounded-lg font-bold bg-red-600 text-white">
            Locked
          </span>
        ) : (
          <span className="px-2 py-1 text-xs rounded-lg font-bold bg-green-600 text-white">
            Open
          </span>
        )}
      </h1>

      {/* Cards in flex wrap, 1px gap */}
      <div className="flex flex-wrap space-x-px space-y-px">
        {editions.map((edition) => {
          const tier = tierMap[edition.playID] || "unknown";
          const imageUrl = imageUrls[edition.playID] || "";
          const ownedCount =
            user?.loggedIn && nftCollection?.[edition.playID]
              ? nftCollection[edition.playID].length
              : 0;

          // --- FIX: Pass the set info to the card ---
          const nftObj = {
            FullName: edition.FullName,
            playID: edition.playID,
            tier,
            retired: !!edition.retired,
            momentCount: edition.momentCount,
            imageUrl,
            ownedCount,

            // Add these lines:
            setID: currentSet.id,
            setName: currentSet.name,
          };

          return (
            <MomentCard
              key={edition.playID}
              nft={nftObj}
              disableHover={true}
              width="w-28"
              height="h-44"
            />
          );
        })}
      </div>
    </div>
  );
};

export default TopShotSets;
