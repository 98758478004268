// TopShotHelpers.js

import _ from "lodash";

// Cache for tier data
let tierCache = null;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

/**
 * TIER_CONFIG contains all tier information:
 * - priority: lower number = higher tier.
 * - label: user-friendly label.
 * - text: Tailwind text style (for filters, etc.).
 * - background: gradient background settings.
 */
export const TIER_CONFIG = {
  ultimate: {
    priority: 5,
    label: "Ultimate",
    text: "text-pink-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r animate-fast-wave",
        bg: "from-pink-300 via-purple-400 to-indigo-300",
      },
    },
  },
  legendary: {
    priority: 4,
    label: "Legendary",
    text: "text-orange-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r animate-fast-wave",
        bg: "from-orange-400 via-orange-500 to-orange-300",
      },
    },
  },
  rare: {
    priority: 3,
    label: "Rare",
    text: "text-blue-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r",
        bg: "from-blue-500 to-blue-500",
      },
    },
  },
  fandom: {
    priority: 2,
    label: "Fandom",
    text: "text-lime-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r",
        bg: "from-lime-500 to-lime-500",
      },
    },
  },
  common: {
    priority: 1,
    label: "Common",
    text: "text-gray-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r",
        bg: "from-gray-500 to-gray-500",
      },
    },
  },
  /**
   * New "mixed" tier for sets or moments with multiple distinct tier values
   */
  mixed: {
    priority: 6,
    label: "Mixed",
    text: "text-purple-500",
    background: {
      gradient: {
        class: "bg-gradient-to-r",
        bg: "from-gray-500 via-lime-500 to-blue-500",
      },
    },
  },

  unknown: {
    priority: 7,
    label: "Unknown",
    text: "text-red-500",
    background: {
      gradient: {
        class: "",
        bg: "bg-red-500",
      },
    },
  },
};

/**
 * TIER_ORDER is computed by sorting the keys of TIER_CONFIG by priority.
 */
export const TIER_ORDER = Object.keys(TIER_CONFIG).sort(
  (a, b) => TIER_CONFIG[a].priority - TIER_CONFIG[b].priority
);

export function getTierButtonClasses(tier) {
  const lower = tier ? tier.toLowerCase() : "unknown";
  const conf = TIER_CONFIG[lower] || TIER_CONFIG.unknown;

  // Combine the class + bg from TIER_CONFIG into one string, plus "text-white"
  const gradientClass = conf.background.gradient.class; // e.g. "bg-gradient-to-r"
  const gradientBg = conf.background.gradient.bg; // e.g. "from-blue-500 to-blue-500"
  return `${gradientClass} ${gradientBg} text-white`;
}

/**
 * TIER_LABEL_MAP maps each tier key to its user-friendly label.
 */
export const TIER_LABEL_MAP = Object.fromEntries(
  Object.entries(TIER_CONFIG).map(([key, config]) => [key, config.label])
);

/**
 * generateCompositeTier(tiers):
 * - Accepts an array of tier strings.
 * - Filters out "none", lowercases, deduplicates, sorts by priority,
 *   and joins them with "/" (e.g. "rare/common").
 *   (Used if you want the old slash-joined approach.)
 */
export function generateCompositeTier(tiers) {
  const valid = tiers
    .filter((t) => t && t.toLowerCase() !== "none")
    .map((t) => t.toLowerCase());
  const unique = [...new Set(valid)];
  unique.sort(
    (a, b) =>
      (TIER_CONFIG[a]?.priority ?? 99) - (TIER_CONFIG[b]?.priority ?? 99)
  );
  return unique.join("/");
}

/**
 * getTierText(tier):
 * Returns the text style (Tailwind class) for a given tier.
 */
export function getTierText(tier) {
  const lower = tier ? tier.toLowerCase() : "unknown";
  return TIER_CONFIG[lower]
    ? TIER_CONFIG[lower].text
    : TIER_CONFIG.unknown.text;
}

/**
 * getTierBackground(tierString):
 * For a single tier or a slash-joined composite, returns the gradient classes
 * from the highest-priority tier in the composite.
 */
export function getTierBackground(tierString) {
  if (!tierString) {
    return `${TIER_CONFIG.unknown.background.gradient.class} ${TIER_CONFIG.unknown.background.gradient.bg}`.trim();
  }
  const parts = tierString.split("/").map((t) => t.toLowerCase().trim());
  parts.sort(
    (a, b) =>
      (TIER_CONFIG[a]?.priority ?? 99) - (TIER_CONFIG[b]?.priority ?? 99)
  );
  const primary = parts[0];
  if (TIER_CONFIG[primary]) {
    const { class: cls, bg } = TIER_CONFIG[primary].background.gradient;
    return `${cls} ${bg}`.trim();
  }
  return `${TIER_CONFIG.unknown.background.gradient.class} ${TIER_CONFIG.unknown.background.gradient.bg}`.trim();
}

/**
 * getSetBackgroundStyle(tierString):
 * For set or moment cards, returns the background classes as a string.
 */
export function getSetBackgroundStyle(tierString = "") {
  return getTierBackground(tierString);
}

/**
 * getNormalizedTier(tierString):
 * - Convert "none" => "unknown"
 * - If there's a slash => "mixed"
 * - otherwise, return it in lowercase or "unknown".
 */
export function getNormalizedTier(tierString) {
  if (!tierString) return "unknown";
  const lower = tierString.trim().toLowerCase();
  if (lower === "none") return "unknown";
  if (lower.includes("/")) return "mixed";
  return lower;
}

/**
 * fetchTierData():
 * Caches data from /topshot-tiers.
 * Maps each setID => final tier (lowercase).
 */
export async function fetchTierData() {
  try {
    const currentTime = Date.now();
    if (tierCache && currentTime - lastFetchTime < CACHE_DURATION) {
      return tierCache;
    }
    const response = await fetch("https://api.vaultopolis.com/topshot-tiers");
    if (!response.ok) throw new Error("Failed to fetch tier data");

    const data = await response.json();
    const finalData = data.reduce((acc, item) => {
      acc[item.setID] = (item.defaultTier || "unknown").toLowerCase();
      return acc;
    }, {});
    tierCache = finalData;
    lastFetchTime = currentTime;
    return finalData;
  } catch (err) {
    console.error("Error fetching tier data:", err);
    return {};
  }
}

/**
 * getTopShotSetTier(setId):
 * Convenience function for a single set's tier.
 */
export async function getTopShotSetTier(setId) {
  const tiers = await fetchTierData();
  return tiers[setId] || "unknown";
}

/**
 * getEditionTier(setId, playId):
 * Optional edition-level tier logic.
 */
export async function getEditionTier(setId, playId) {
  return "unknown";
}

export default {
  TIER_CONFIG,
  TIER_ORDER,
  TIER_LABEL_MAP,
  generateCompositeTier,
  getTierText,
  getTierBackground,
  getSetBackgroundStyle,
  getNormalizedTier,
  fetchTierData,
  getTopShotSetTier,
  getEditionTier,
};
