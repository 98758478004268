import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as fcl from "@onflow/fcl";

// Configure FCL directly here
fcl
  .config()
  .put("flow.network", "mainnet")
  .put("accessNode.api", "https://rest-mainnet.onflow.org")
  .put("discovery.wallet", "https://fcl-discovery.onflow.org/authn")
  .put("discovery.authn.endpoint", "https://fcl-discovery.onflow.org/api/authn")
  // Removed walletconnect.projectId
  .put("app.detail.title", "FlowConnect")
  .put("app.detail.icon", "https://flowconnect.io/favicon.png")
  .put("app.detail.description", "FlowConnect")
  .put("app.detail.url", "https://flowconnect.io")
  .put("0xFlowToken", "0x1654653399040a61")
  .put("discovery.authn.exclude", [
    // If you want to exclude certain wallets from Discovery
    // "0x95b85a9ef4daabb1", "0xf086a545ce3c552d"
  ]);

// Create React root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Optional performance measurements
reportWebVitals();
